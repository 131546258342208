<template>
    <div id="config-business" class="container-fluid mb-4">

        <div class="row bg-white">

            <div class="col-12 bg-white py-3">
                <div class="row bg-lightgray py-3 opensans-bold">

                    <div class="col-3">
                        <ki-input @input="search"
                                  label="Busqueda:"
                                  placeholder="Buscar..."
                                  type="text"
                                  v-model="search_query"
                                  full>
                        </ki-input>
                    </div>

                    <div class="col-4 d-flex align-items-end">
                        <button @click="business_modal = true"
                                class="btn btn-white pills"
                                type="button">
                            <img :src="icon_add" />
                            Agregar nueva empresa
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-12 bg-white py-3" v-if="loading">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12 bg-white" v-if="fetch_error">
                <p class="m-0">
                    Un error ha ocurrido intentalo más tarde
                </p>
            </div>

            <div class="col-12 p-3  bg-white" v-if="!loading && !fetch_error">
                <ki-table :data="data_table"
                          :allow_headers="headers_allow"
                          no_pagination color_headers="white" color_body="white">
                    <template v-slot:Editar="data">
                        <button @click="selectCompany('edit',data.item.id_business)"
                                class="pills bg-white p-0"
                                style="width: 50px"
                                type="button">
                            <img :src="icon_write">
                        </button>
                    </template>
                    <template v-slot:Borrar="data">
                        <button @click="selectCompany('delete', data.item.id_business)"
                                class="pills bg-white p-0"
                                style="width: 50px"
                                type="button">
                            <img :src="icon_delete_red" />
                        </button>
                    </template>
                </ki-table>
            </div>

            <!-- Paginator -->
            <div class="col-12 p-0 m-0 mt-2 pb-3">
                <div class="row justify-content-between align-items-center text-dark">
                    <div class="col d-flex">
                        <label class="mr-2">
                            Elementos por página:
                        </label>
                        <ki-input @keyup.enter="navigate"
                                  v-model="rows"
                                  :disabled="current_page > 1">
                        </ki-input>
                    </div>

                    <div class="col-1  d-flex justify-content-end p-0">
                        <button @click.stop="prev"
                                class="btn btn-primary text-white pills"
                                type="button"
                                :disabled="current_page == 1">
                            <b-icon icon="arrow-left"
                                    variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1" style="max-width: 80px;">
                        <ki-input @keyup.enter="navigate"
                                  v-model="current_page"
                                  full>
                        </ki-input>
                    </div>

                    <div class="col-1">
                        <p class="m-0">
                            de {{  total_pages  }}
                        </p>
                    </div>

                    <div class="col-1 p-0" style="max-width: 65px">
                        <button @click.stop="next"
                                class="btn btn-primary text-white pills"
                                :disabled="current_page == total_pages"
                                type="button">
                            <b-icon icon="arrow-right"
                                    variant="white">
                            </b-icon>
                        </button>
                    </div>

                </div>
            </div>
        </div>


        <!--create edit users-->
        <ki-modal v-if="business_modal">
            <div class="row bg-white text-dark position-relative p-2 opensans-bold" style="border-radius: 10px">
                <button @click="clearData"
                        style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                        class="btn btn-white p-0 position-absolute"
                        type="button">
                    X
                </button>

                <div class="col-12" v-if="!selected_id">
                    <p>
                        Añadir nueva Empresa
                    </p>
                </div>
                <div class="col-12" v-if="selected_id">
                    <p>
                        Editar Empresa
                    </p>
                </div>

                <div class="col-6 col-lg-4">
                    <ki-input
                        v-model="business_name"
                        label="Nombre:"
                        placeholder="Nombre de la empresa"
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_company.business_name">
                        {{ errors_company.business_name }}
                    </span>
                </div>

                <div class="col-6 col-lg-4">
                    <ki-input
                        v-model="business_street"
                        label="Dirección:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_company.business_street">
                        {{ errors_company.business_street }}
                    </span>
                </div>

                <div class="col-6 col-lg-4 my-2 my-lg-0">
                    <ki-input
                        v-model="business_external_number"
                        label="Número externo"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_company.business_external_number">
                        {{ errors_company.business_external_number }}
                    </span>
                </div>
	            
	            <div class="col-6 col-lg-4">
		            <ki-input
			            v-model="business_internal_number"
			            label="Número interno:"
			            type="text"
			            full>
		            </ki-input>
		            <span class="text-danger" v-if="errors_company.business_external_number">
                        {{ errors_company.business_external_number }}
                    </span>
	            </div>
	            
	            <div class="col-6 col-lg-4">
		            <ki-input
			            v-model="business_municipality"
			            label="Municipio:"
			            placeholder=""
			            type="text"
			            full>
		            </ki-input>
		            <span class="text-danger" v-if="errors_company.business_municipality">
                        {{ errors_company.business_municipality}}
                    </span>
	            </div>


                <div class="col-6 col-lg-4 my-2 my-lg-0">
                    <ki-input
                        v-model="business_rfc"
                        label="RFC:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_company.business_rfc">
                        {{ errors_company.business_rfc }}
                    </span>
                </div>

                

                <div class="col-6 col-lg-4">
                    <ki-input
                        v-model="business_email"
                        label="Email:"
                        placeholder=""
                        type="email"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_company.business_email">
                        {{ errors_company.business_email }}
                    </span>
                </div>


                <div class="col-6 col-lg-4 my-2 my-lg-0">
                    <ki-input
                        v-model="business_phone"
                        label="Telefono:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_company.business_phone">
                        {{ errors_company.business_phone }}
                    </span>
                </div>

                <div class="col-6 col-lg-4 my-2 my-lg-0">
                    <ki-input
                        v-model="business_picture"
                        label="Imagen:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_company.business_picture">
                        {{ errors_company.business_picture }}
                    </span>
                </div>
	            
	            <div class="col-6 col-lg-4 my-2 my-lg-0">
		            <ki-input
			            v-model="business_manager_name"
			            label="Genrente:"
			            placeholder=""
			            type="text"
			            full>
		            </ki-input>
		            <span class="text-danger" v-if="errors_company.business_manager_name">
                        {{ errors_company.business_manager_name }}
                    </span>
	            </div>

                <div class="col-6 col-lg-12 p-3 d-flex justify-content-end">
                    <button @click="clearData"
                            class="btn btn-white pills"
                            type="button">
                        Cancelar
                    </button>

                    <button @click="createUpdateCompany"
                            class="btn btn-primary pills text-white ml-3"
                            type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!---Modal delete employee-->
        <ki-modal v-if="delete_modal">
            <div class="row bg-white justify-content-center p-4 position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                        class="position-absolute btn btn-white p-0"
                        style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2);"
                        type="button">
                    X
                </button>

                <div class="col-12 text-black text-center opensans-bold">
                    <p>
                        ¿Está seguro de borrar esta empresa?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click="deleteCompany"
                            class="btn btn-danger pills text-white"
                            type="button">
                        Borrar
                    </button>

                    <button @click="clearData"
                            class="btn btn-white pills ml-4"
                            type="button">
                        Cancelar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>

//Icons
import icon_write from 'ASSETS/icons/icon_write.png';
import icon_delete_red from 'ASSETS/icons/icon_delete_red.png';
import icon_add from 'ASSETS/icons/plus-blue-icon.png'

//helpers
import { mapGetters  } from 'vuex';

export default {
    name: 'ki-config-employees',
    data() {
        return {
            icon_write,
            icon_delete_red,
            icon_add,
            loading: true,
            fetch_error: false,
            business_modal: false,
            delete_modal: false,
            selected_id: false,
            business_name: null,
            business_street: null,
            business_external_number: null,
            business_internal_number: null,
            business_municipality: null,
            business_email: null,
            business_phone: null,
            business_rfc: null,
            business_picture: null,
            business_manager_name: null,
            headers_allow: [
                { key: 'business_name', label: "Empresa"},
                { key: 'business_street', label: "Direccion"},
                { key: 'business_municipality', label: "Municipio"},
                { key: 'business_email', label: "Correo"},
                { key: 'business_phone', label: "Telefono"},
                { key: 'business_rfc', label: "RFC"},
                { key: 'Editar', label: "Editar", width: '60' },
                { key: 'Borrar', label: "Borrar", width: '60' },
            ],
            data_table: [],
            errors_company: {
                business_name: null,
                business_street: null,
                business_external_number: null,
                business_internal_number: null,
                business_municipality: null,
                business_email: null,
                business_phone: null,
                business_rfc: null,
                business_picture: null,
                business_manager_name: null,
            },
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            companies: 'companies/getCompanies',
            company: 'companies/getCompany',
            total_pages: 'companies/getTotalPages',
            total_rows: 'companies/getTotalRows'
        })
    },
    methods: {
        //helpers
        clearData(){
            this.selected_id  = null
            this.business_modal = false
            this.delete_modal = false
	        this.business_name = null
	        this.business_street = null
	        this.business_external_number = null
	        this.business_internal_number = null
	        this.business_municipality = null
	        this.business_email = null
	        this.business_phone = null
	        this.business_rfc = null
	        this.business_picture = null
	        this.business_manager_name = null

            for(let error in this.errors_company) {
                this.errors_company[error] = null
            }

        },
        async selectCompany(type, id){
            switch(type){
                case 'edit':
                    this.selected_id = id
                    await this.$store.dispatch('companies/viewCompany', { id_business_information: this.selected_id })
	                this.business_name = this.company.business_name
	                this.business_street = this.company.business_street
	                this.business_external_number = this.company.business_external_number
	                this.business_internal_number = this.company.business_internal_number
	                this.business_municipality = this.company.business_municipality
	                this.business_email = this.company.business_email
	                this.business_phone = this.company.business_phone
	                this.business_rfc = this.company.business_rfc
	                this.business_picture = this.company.business_picture
	                this.business_manager_name = this.company.business_manager_name
                    this.business_modal = true
                    break;
                case 'delete':
                    this.selected_id  = id
                    this.delete_modal  = true
                    break;
            }
        },

        //employee CRUD
        async createUpdateCompany() {

            for(let error in this.errors_employee){
                this.errors_employee[error] = null
            }

            let complete = true


            if(_.isNull(this.business_name) || _.isEmpty(this.business_name)) {
                this.errors_company['business_name'] = '*Campo obligatorio'
                complete = false
            }
	        
	        if(_.isNull(this.business_street) || _.isEmpty(this.business_street)) {
		        this.errors_company['business_street'] = '*Campo obligatorio'
		        complete = false
	        }
	        
	        if(_.isNull(this.business_municipality) || _.isEmpty(this.business_municipality)) {
		        this.errors_company['business_municipality'] = '*Campo obligatorio'
		        complete = false
	        }
	        
	        if(_.isNull(this.business_external_number) || _.isEmpty(this.business_external_number)) {
		        this.errors_company['business_municipality'] = '*Campo obligatorio'
		        complete = false
	        }
	        
	        if(_.isNull(this.business_email) || _.isEmpty(this.business_email)) {
		        this.errors_company['business_email'] = '*Campo obligatorio'
		        complete = false
	        }
	        
	        if(_.isNull(this.business_rfc) || _.isEmpty(this.business_rfc)) {
		        this.errors_company['business_rfc'] = '*Campo obligatorio'
		        complete = false
	        }
	        
	        if(_.isNull(this.business_manager_name) || _.isEmpty(this.business_manager_name)) {
		        this.errors_company['business_manager_name'] = '*Campo obligatorio'
		        complete = false
	        }

            if(complete) {
                const payload = {
	                business_name: this.business_name,
	                business_street: this.business_street,
	                business_external_number: this.business_external_number,
	                business_internal_number: this.business_internal_number,
	                business_municipality: this.business_municipality,
	                business_email: this.business_email,
	                business_phone: this.business_phone,
	                business_rfc: this.business_rfc,
	                business_picture: this.business_picture,
	                business_manager_name: this.business_name,
                }

                if(this.selected_id) {

                    let data = {
                        body: payload,
                        query: {
                            id_business_information: this.selected_id
                        }
                    }
                    await this.$store.dispatch('companies/editCompany', data)
                    await this.navigate();
                    this.clearData()
                }

                else {
                    await this.$store.dispatch('companies/insertCompany', payload )
                    await this.navigate();
                    this.clearData();
                    this.employe_modal = false
                }
            }

        },
        async deleteCompany() {

            await this.$store.dispatch('companies/deleteCompany', { id_business_information: this.selected_id} )
            await this.navigate()
            this.delete_modal  = false
            this.selected_id = false
        },
        async search() {
            await this.navigate();
        },
        //paginator
        async navigate(){
            this.loading = true
            this.empty_search = false

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }

            await this.$store.dispatch('companies/listCompanies', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})


            if(!this.companies) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.companies
            }

            this.loading = false;
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async mounted() {
        try {
            await this.navigate()
            this.fetch_error = false
        }
        catch (e) {
            this.loading = false
            this.fetch_error = true
        }

    }
};
</script>