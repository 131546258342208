<template>
    <div id="Transaction_Types" class="row bg-white text-dark opensans mb-5">

        <div class="col-12">
            <div class="row bg-lightgray align-items-end py-3 my-3">
                <div class="col-6 col-xl-3 opensans-bold">
                    <ki-input @input="search"
	                    label="Buscar:"
                        placeholder="Ej: tarjeta de crédito"
                        v-model="search_query"
                        type="text"
                        full>
                    </ki-input>
                </div>
                <div class="col-6 col-xl-3">
                    <button @click.stop="open_modal = true"
                        class="btn btn-white pills"
                        type="button">
                        <img :src="add_icon" />
                        Añadir forma de pago
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12 py-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div class="col-12 text-center" v-if="!loading && error">
            <p style="font-size: 24px">
                Un error ha ocurrido intente más tarde
            </p>
        </div>

        <div class="col-12 mb-3" v-if="!loading && !error && data_table.length > 0">
            <ki-table :data="data_table"
                :allow_headers="data_table_headers"
                no_pagination color_body="white" color_headers="white">
                <template v-slot:Editar="data">
                    <button @click.stop="selectTransactionType('edit', data.item.id_transaction_type)"
                        class="btn btn-white pills p-0"
                        :disabled="data.item.id_transaction_type == 1 || data.item.id_transaction_type == 2 || data.item.id_transaction_type == 3"
                        style="width: 50px"
                        type="button">
                        <img :src="write_icon" />
                    </button>
                </template>
                <template v-slot:Borrar="data">
                    <button @click.stop="selectTransactionType('delete', data.item.id_transaction_type)"
                        class="btn btn-white pills p-0"
                        :disabled="data.item.id_transaction_type == 1 || data.item.id_transaction_type == 2 || data.item.id_transaction_type == 3"
                        style="width: 50px"
                        type="button">
                        <img :src="trash_icon"  />
                    </button>
                </template>
            </ki-table>
        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página: 
                    </label>
                    <ki-input @keyup.enter="navigate"
                        v-model="rows"
                        :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                        class="btn btn-primary text-white pills"
                        type="button"
                        :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                        v-model="current_page"
                        full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                        class="btn btn-primary text-white pills"
                        :disabled="current_page == total_pages"
                        type="button">
                        <b-icon icon="arrow-right"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <ki-modal v-if="open_modal">
            <div class="row bg-white p-3 position-relative" style="border-radius: 10px">

                <button @click.stop="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,)"
                    type="button ">
                    X
                </button>

                <div class="col-12">
                    <p v-if="!selected_id">
                        Añadir forma de pago
                    </p>
                    <p v-if="selected_id">
                        Editar forma de de pago
                    </p>
                </div>

                <div class="col-12">
                    <ki-input label="Forma de pago"
                        placeholder="Ej: Efectivo"
                        v-model="transaction_type_name"
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_transaction">
                        {{ error_transaction }}
                    </span>
                </div>

                <div class="col-12 p-2 d-flex justify-content-end">
                    <button @click.stop="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="createEditTransactionType"
                        class="btn btn-primary pills text-white ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="delete_modal">
            <div class="row bg-white text-dark p-5 position-relative" style="border-radius: 10px;">
                <button @click.stop="clearData"
                    class="btn btn-white position-absolute p-0"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                    type="button">
                    X
                </button>

                <div class="col-12 text-center opensans-bold">
                    <p>
                        ¿Esta seguro de eliminar esta forma de pago?
                    </p>
                </div>
                
                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="deleteTransactionType"
                        class="btn btn-danger text-white pills ml-3"
                        type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //icons
    import add_icon from 'ASSETS/icons/plus-blue-icon.png'
    import write_icon from 'ASSETS/icons/icon_write.png'
    import trash_icon from 'ASSETS/icons/icon_delete_red.png'

    //helpers
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                add_icon,
                write_icon,
                trash_icon,
                open_modal: false,
                delete_modal: false,
                transaction_type_name: null,
                selected_id: null,
                loading: false,
                error: false,
                error_transaction: null,
                data_table_headers: [
                    {key: 'transaction_name', label:'Forma de pago'},
                    {key: 'Editar', width: 30},
                    {key:'Borrar', width: 30}
                ],
                data_table:[],
                //paginator
                current_page: 1,
                rows: 10,
                search_query: ''
            }
        },
        computed: {
            ...mapGetters({
                transaction_types: 'transaction_types/getTransactions',
                transaction_type: 'transaction_types/getTransaction',
                total_pages: 'transaction_types/getTotalPages',
                total_rows: 'transaction_types/getTotalRows'
            })
        },
        methods: {
            //helpers
            clearData() {
                this.open_modal = false
                this.delete_modal = false
                this.transaction_type_name = null
                this.error_transaction = null
            },
            async selectTransactionType(type, id){
                switch(type){
                    case 'edit':
                        this.selected_id = id
                        await this.$store.dispatch('transaction_types/viewTransactionsTypes', { id_transaction_type: this.selected_id })
                        this.transaction_type_name = this.transaction_type.transaction_name
                        this.open_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                }
            },
            async createEditTransactionType(){

                this.error_transaction = null
                let is_complete = true

                if(_.isNull(this.transaction_type_name) || _.isEmpty(this.transaction_type_name)) {
                    this.error_transaction = '*Campo obligatorio'
                    is_complete = false
                    return
                }

                if(is_complete) {
                    const payload = {
                        name: this.transaction_type_name
                    }

                    if(this.selected_id) {
                        
                        let data = {
                            body: payload,
                            query: {
                                id_transaction_type: this.selected_id
                            }
                        }

                        await this.$store.dispatch('transaction_types/editTransactionsTypes', data)
                        await this.navigate()
                        this.clearData()
                        return
                    }

                    else {
                        await this.$store.dispatch('transaction_types/insertTransactionsTypes', payload)
                        await this.navigate()
                        this.clearData()
                        return
                    }
                }
            },
            async deleteTransactionType() {
                await this.$store.dispatch('transaction_types/deleteTransactionsTypes', { id_transaction: this.selected_id } )
                this.selected_id = null
                await this.navigate()
                this.clearData()
            },
	        async search() {
				await this.navigate();
	        },
            //paginator
            async navigate(){
                this.empty_search = false
                this.loading = true

                if(this.current_page > this.total_pages){
                    this.current_page = this.total_pages == 0 ? 1 : this.current_page;
                }

	            await this.$store.dispatch('transaction_types/listTransactionsTypes', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
	            
                if(!this.transaction_types) {
                    this.empty_search = true;
                    return
                }

                else {
                    this.data_table = this.transaction_types
                }
                
                this.loading = false
            },
            prev(){
                this.current_page--;
                this.navigate()
            },
            next(){
                this.current_page++;
                this.navigate()
            }
        },
        async mounted() {
            try {
                await this.navigate()
                this.error = false
            }
            catch(e) {
                console.error(e)
                this.loading = false
                this.error = true
            }
        }
    }

</script>

<style lang="scss" scoped>
    
</style>