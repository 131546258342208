<template>
    <div id="Clients" class="row bg-white text-dark opensans mb-5">

        <div class="col-12">
            <div class="row bg-lightgray align-items-end py-3 my-3">
                <div class="col-6 col-xl-3 opensans-bold">
                    <ki-input @input="search"
	                    label="Buscar:"
                        placeholder="Ej: Juan Peréz"
                        type="text"
                        v-model="search_query"
                        full>
                    </ki-input>
                </div>
                <div class="col-6 col-xl-3">
                    <button @click.stop="(client_modal = true)"
                        class="btn btn-white pills"
                        type="button">
                        <img :src="add_icon" />
                        Añadir Cliente
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12 py-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div class="col-12 text-center" v-if="(!loading && fetch_error)" >
            <p style="font-size: 24px">
                Un error ha ocurrido intente más tarde
            </p>
        </div>

        <div class="col-12 mb-3" v-if="(!loading && !fetch_error && client_table_data.length > 0 )">
            <ki-table :data="client_table_data"
                :allow_headers="client_table_headers"
                no_pagination color_body="white" color_headers="white">
                <template v-slot:Editar="data">
                    <button @click.stop="selectClient('edit', data.item.id_client)"
                        class="btn btn-white pills p-0"
                        style="width: 50px"
                        type="button">
                        <img :src="write_icon" />
                    </button>
                </template>
                <template v-slot:Borrar="data">
                    <button @click.stop="selectClient('delete', data.item.id_client)"
                        class="btn btn-white pills p-0"
                        style="width: 50px"
                        type="button">
                        <img :src="trash_icon" />
                    </button>
                </template>
            </ki-table>
        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página: 
                    </label>
                    <ki-input @keyup.enter="navigate"
                        v-model="rows"
                        :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                        class="btn btn-primary text-white pills"
                        type="button"
                        :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                        v-model="current_page"
                        full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                        class="btn btn-primary text-white pills"
                        :disabled="current_page == total_pages"
                        type="button">
                        <b-icon icon="arrow-right"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <!--Modals-->

        <ki-modal v-if="client_modal">
            <div class="row bg-white position-relative text-dark" style="border-radius: 10px">
                <button @click.stop="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.4)"
                    type="button">
                    X
                </button>

                <div class="col-12">
                    <p class="text-dark" v-if="!selected_client_id">
                        Añadir nuevo cliente
                    </p>
                    <p class="text-dark" v-if="selected_client_id">
                        Editar un cliente existente
                    </p>
                </div>

                <div class="col-6">
                    <ki-input label="Nombre:"
                        placeholder="Ej: Alejandro Alferéz"
                        type="text"
                        v-model="name"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.name">
                        {{ errors.name}}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input label="RFC:"
                        placeholder="Ej: 'XXA0101010101'"
                        type="text"
                        v-model="rfc"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.rfc">
                        {{ errors.rfc }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input label="Razón social:"
                        placeholder="Ej: EMPRESA LOS REYES"
                        type="text"
                        v-model="company_name"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.company_name">
                        {{ errors.company_name }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-select label="Régimen fiscal:"
                        :options="[{id: 1, label: 'régimen 1'}, { id: 2, label: 'régimen 2'}]"
                        v-model="id_fiscal_regiment"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="errors.id_fiscal_regiment">
                        {{ errors.id_fiscal_regiment}}
                    </span>
                </div>

                <div class="col-6">
                    <ki-select label="tipo de contribuyente:"
                        :options="[{id: 'fisica', label: 'Fisica'}, { id: 'moral', label: 'Moral'}]"
                        v-model="type_person"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="errors.type_person">
                        {{ errors.type_person }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input label="Dirección:"
                        placeholder="Ej: Esmeralda #2345"
                        type="text"
                        v-model="address"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.address">
                        {{ errors.address }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input label="Télefono:"
                        placeholder="Ej: 9999999999"
                        type="number"
                        v-model="phone"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.phone">
                        {{ errors.phone }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input label="Correo eléctronico:"
                        placeholder="Ej: juanalferez@mail.com"
                        type="email"
                        v-model="email"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.email">
                        {{ errors.email}}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input label="Descuento:"
                        placeholder="Ej: 5"
                        type="email"
                        v-model="discount_percent"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.discount">
                        {{ errors.discount }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input label="Código postal:"
                        placeholder="Ej: 45340"
                        type="email"
                        v-model="postal_code"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.postal_code">
                        {{ errors.postal_code }}
                    </span>
                </div>

                <div class="col-12 p-3 d-flex justify-content-end">
                    <button @click.stop="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="createEditClient"
                        class="btn btn-primary pills text-white ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="delete_modal">
            <div class="row bg-white position-relative p-3" style="border-radius: 10px">
                <button @click.stop="clearData()"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.4)"
                    type="button">
                    X
                </button>
                <div class="col-12 text-center opensans-bold">
                    <p>
                        ¿Esta seguro de borrar este cliente?
                    </p>
                </div>

                <div class="col-12 p-3 d-flex justify-content-center">
                    <button @click.stop="clearData()"
                        class="btn btn-primary pills ml-2 text-white"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="deleteClient"
                        class="btn btn-danger pills ml-2 text-white"
                        type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>

    </div>
</template>

<script>
    //icons 
    import add_icon from 'ASSETS/icons/plus-blue-icon.png'
    import write_icon from 'ASSETS/icons/icon_write'
    import trash_icon from 'ASSETS/icons/icon_delete_red.png'

    //helpers
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                add_icon,
                write_icon,
                trash_icon,
                loading: true,
                client_modal: false,
                delete_modal: false,
                selected_client_id: null,
                fetch_error: false,
                client_table_data: [],
                client_table_headers: [
                    {key: 'client_name', label: 'Nombre' },
                    {key: 'client_rfc', label: 'RFC'},
                    {key: 'client_company_name', label: 'Razón Social'},
                    {key: 'client_type_person', label: 'Tipo'},
                    {key: 'client_phone', label: 'Teléfono'},
                    {key: 'client_email', label: 'Correo'},
                    {key: 'client_discount_percent', label: 'Descuento'},
                    {key: 'Editar'},
                    {key: 'Borrar'}
                ],
                regimen_options:[],
                name: null,
                rfc: null,
                company_name: null,
                id_fiscal_regiment: 1,
                type_person: 'fisica',
                address: null,
                phone: null,
                email: null,
                discount_percent: null,
                postal_code: null,
                errors: {
                    name: null,
                    rfc: null,
                    company_name: null,
                    type_person: null,
                    phone: null,
                    email: null,
                    discount_percent: null,
                    address: null,
                    id_fiscal_regiment: null,
                    postal_code: null
                },
                //paginator
                current_page: 1,
                rows: 10,
                search_query: ''
            };
        },
        computed: {
            ...mapGetters({
                clients: 'clients/getClients',
                client: 'clients/getClient',
                total_pages: 'clients/getTotalPages',
                total_rows: 'clients/getTotalRows'
            })
        },
        methods: {
            //helpers
            isEmail (value) {
                let exp_reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

                if(exp_reg.test(value)) {
                    return true
                }
                else {
                    return false
                }
            },
            clearData() {
                this.name = null
                this.rfc = null
                this.company_name = null
                this.id_fiscal_regiment = null
                this.type_person = null
                this.phone = null
                this.email = null
                this.discount_percent = null
                this.address = null
                this.postal_code = null

                this.selected_client_id = null

                this.client_modal = false
                this.delete_modal = false

                for(let error in this.errors) {
                    this.errors[error] = null
                }

            },
            async selectClient(type, id){
                switch(type) {
                    case 'edit':
                        this.selected_client_id = id

                        await this.$store.dispatch('clients/viewClient', { id_client: this.selected_client_id })
                        console.log(this.client)
                        this.name = this.client.client_name
                        this.rfc = this.client.client_rfc,
                        this.company_name = this.client.client_company_name
                        this.id_fiscal_regiment = this.client.id_fiscal_regiment
                        this.type_person = this.client.client_type_person
                        this.phone = this.client.client_phone
                        this.email = this.client.client_email
                        this.discount_percent = this.client.client_discount_percent.toString()
                        this.address = this.client.client_address
                        this.postal_code = this.client.client_postal_code
                        this.client_modal = true

                        break;
                    case 'delete':
                        this.selected_client_id = id;
                        this.delete_modal = true
                        break;
                    
                }
            },

            //client CRUDS
            async createEditClient () {

                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let is_complete = true

                if(_.isNull(this.name) || _.isEmpty(this.name)) {
                    this.errors.name = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.rfc) || _.isEmpty(this.rfc)) {
                    this.errors.rfc = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.company_name) || _.isEmpty(this.company_name)) {
                    this.errors.company_name = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.id_fiscal_regiment)) {
                    this.errors.id_fiscal_regiment = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.type_person) || _.isEmpty(this.type_person)) {
                    this.errors.type_person = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.phone) || _.isEmpty(this.phone)) {
                    this.errors.phone= '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.postal_code) || _.isEmpty(this.postal_code)) {
                    this.errors.postal_code = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.address)) {
                    this.errors.address = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.email) || _.isEmpty(this.email)) {
                    this.errors.email = '*Campo obligatorio'
                    is_complete = false
                }

                else if(!this.isEmail(this.email)) {
                    this.errors.email = '*Correo no válido'
                    is_complete = false
                }

                if(_.isNull(this.discount_percent) || _.isEmpty(this.discount_percent)) {
                    this.errors.discount = '*Campo obligatorio'
                    is_complete = false
                    return
                }

                if(is_complete) {

                    let payload = {
                        name: this.name,
                        rfc: this.rfc,
                        company_name: this.company_name,
                        type_person: this.type_person,
                        postal_code: this.postal_code,
                        address: this.address,
                        id_fiscal_regiment: this.id_fiscal_regiment, 
                        email: this.email,
                        phone: this.phone,
                        discount_percent: this.discount_percent,
                    }

                    if(this.selected_client_id) {
                        let data = {
                            body: payload,
                            query: {
                                id_client: this.selected_client_id
                            }
                        }
                        
                        await this.$store.dispatch('clients/editClient', data)
                        await this.navigate()
                        this.clearData()
                    } 

                    else  {
                        await this.$store.dispatch('clients/addClient', payload)
                        await this.navigate()
                        this.clearData()
                    }
                    

                }
            },
            async deleteClient() {
                await this.$store.dispatch('clients/deleteClient', { id_client: this.selected_client_id})
                await this.navigate()

                this.selected_client_id = null
                this.delete_modal = false
            },
	        async search() {
				await this.navigate();
	        },

            //paginator
            async navigate(){
                this.empty_search = false
                this.loading = true

                if(this.current_page > this.total_pages){
                    this.current_page = this.total_pages == 0 ? 1 : this.current_page;
                }
	            await this.$store.dispatch('clients/listClients', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
                
                        
                if(!this.clients) {
                    this.empty_search = true;
                    return
                }

                else {
                    this.client_table_data = this.clients
                }
                        
                this.loading = false
            },
            prev(){
                this.current_page--;
                this.navigate()
            },
            next(){
                this.current_page++;
                this.navigate()
            }
        },
        async mounted() {
            try {

                //list of clients
                await this.navigate()
                this.fetch_error = false
            }

            catch(error) {
                console.log(error)
                this.loading = false
                this.fetch_error = true
                this.client_table_data = []
            }
        }
    }
</script>