<template>
    <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box text-dark">
        <div class="row">
            
            <div class="col-12">

                <div class="row bg-lightgray py-3 my-2">
                    <div class="col-6 col-xl-3 opensans-bold">
                        <ki-input @input="search"
                            label="Buscar:"
                            v-model="search_query"
                            placeholder="Ej: Pantalón..."
                            type="text"
                            full>
                        </ki-input>
                    </div>
                    <div class="col-6 col-xl-3 opensans-bold">
                        <ki-select @change="filterByDiscount"
                            label="Filtrar por descuento:"
                            :options="[...discount_options, {id: 0, label: 'Mostrar todos'}]"
                            :hidden_option="'Seleccione'"
                            v-model="discount_filter"
                            type="text"
                            full>
                        </ki-select>
                    </div>
                    <div class="col-12 col-xl-6 d-flex align-items-end  pt-2 pt-xl-0">
                        <button @click="open_modal = true"
                            class="btn btn-white pills"
                            type="button">
                            <img :src="add_icon" />
                            Añadir producto
                        </button>
                        <button @click="product_discount_modal = true"
                            class="ml-3 btn btn-white pills"
                            :disabled="product_discount_table_data.length <= 0"
                            type="button">
                            <span class="text-primary"
                                style="font-size: 20px;">
                                %
                            </span>
                            Administrar descuentos
                        </button>
                    </div>

                </div>
                
            </div>
            


            <div class="col-12 py-2" v-if="loader">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12 py-2" v-if="!loader && product_data_table.length > 0 && !filtered">
                <ki-table :data="product_data_table"
                    :allow_headers="product_data_headers"
                    no_pagination color_body="white" color_headers="white">
                    <template v-slot:seleccion="data">
                        <ki-checkbox  @click="selectedItem(data.item, 'P_TABLE')"
                            v-model="data.item.checked" fill/>
                    </template>

                    <template v-slot:unit_cost="data">
                        ${{ formatAmount(data.value) }}
                    </template>
                    <template v-slot:sell_price="data">
                        ${{ formatAmount(data.value) }}
                    </template>
                    <template v-slot:retail_price="data">
                        ${{ formatAmount(data.value) }}
                    </template>


                    <template v-slot:Editar="data">
                        <button @click="selectEditProduct(data)"
                            class="btn btn-white pills p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="write_icon" />
                        </button>
                    </template>
                    <template v-slot:Borrar="data">
                        <button @click="selectDeleteProduct(data)"
                            class="btn btn-white pills p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="delete_icon" />
                        </button>
                    </template>
                </ki-table>
            </div>

            <div class="col-12 py-2" v-if="products_by_discount_table_data.length > 0 && filtered">
                <ki-table :data="products_by_discount_table_data"
                    :allow_headers="products_by_discount_table_headers"
                    no_pagination color_body="white" color_headers="white">
                    <template v-slot:seleccion="data">
                        <ki-checkbox  @click="selectedItem(data.item, 'P_DISCOUNT')"
                            v-model="data.item.checked" fill/>
                    </template>
                    <template v-slot:borrar="data">
                        <button @click="removeProductDiscount(data)"
                            class="btn btn-white pills p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="delete_icon" />
                        </button>
                    </template>
                </ki-table>
            </div>

            <!----- Paginator -->
            <div class="col-12">
                <div class="row justify-content-end align-items-center">
                    <div class="col d-flex">
                        <label class="mr-2">Productos por página: </label>
                        <ki-input @keyup.enter="navigate"
                            v-model="rows">
                        </ki-input>
                    </div>

                    <div class="col-1  d-flex justify-content-end p-0">
                        <button @click.stop="prev"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="current_page == 1">
                            <b-icon icon="arrow-left"
                            variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1" style="max-width: 80px;">
                        <ki-input @keyup.enter="navigate"
                            v-model="current_page"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-1 p-0" style="max-width: 65px">
                        <button @click.stop="next"
                            class="btn btn-primary text-white pills"
                            :disabled="current_page == total_pages"
                            type="button">
                            <b-icon icon="arrow-right"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1">
                        <p class="m-0">
                            de {{  total_pages  }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-12 py-2" v-if="products_by_discount_table_data.length == 0 && filtered">
                <p class="text-center text-dark">
                    No hay productos asignados a este descuento
                </p>
            </div>

        </div>

        <!---create edit product modal-->
        <ki-modal v-if="open_modal">
            <div class="row bg-white position-relative" style="border-radius: 10px">
                <button @click="cancel"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); top: -10px; right: -10px"
                    type="button">
                    X
                </button>
                <div class="col-12" v-if="!selected_id">
                    <p>
                        Añadir un nuevo producto
                    </p>
                </div>
                <div class="col-12" v-if="selected_id">
                    <p>
                        Editar un producto existente
                    </p>
                </div>

                <div class="col-3">
                    <ki-input label="Nombre del producto:"
                        type="text"
                        v-model="product_name"
                        placeholder="EJ: Pantalón" 
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.product_name">
                        {{ errors.product_name}}
                    </span>
                </div>

                <!-- <div class="col-3">
                    <ki-select label="Almacen:"
                        :options="distributor_center_options"
                        v-model="distributor_center"
                        :hidden_option="'Seleccione...'"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="errors.distributor_center">
                        {{ errors.distributor_center }}
                    </span>
                </div> -->


                <div class="col-3">
                    <ki-select label="Subcategoria:"
                        :options="subcategory_options"
                        :hidden_option="'Seleccione...'"
                        v-model="subcategory"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="errors.subcategory">
                        {{ errors.subcategory }}
                    </span>
                </div>


                <div class="col-6">
                    <ki-input label="Descripción:"
                        type="text"
                        v-model="product_description"
                        placeholder="EJ: mezclilla azul" 
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.product_description">
                        {{ errors.product_description }}
                    </span>
                </div>

                <div class="col-2 border p-0 m-2">
                    <div class="row p-1">
                        <div class="col-12">
                            <p class="m-0">
                                Precios sin IVA:
                            </p>
                        </div>
                        <div class="col-12">
                            <ki-input @input="updatePrice('sell', price)"
                                label="Precio mayorista:"
                                v-model="price"
                                type="number"
                                placeholder="00:00"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.price">
                                {{ errors.price }}
                            </span>
                        </div>
                        <div class="col-12">
                            <ki-input @input="updatePrice('retail', price_minor)"
                                label="Precio minorista:"
                                v-model="price_minor"
                                type="number"
                                placeholder="00:00"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.price_minor">
                                {{ errors.price_minor}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-2 border p-0 m-2">
                    <div class="row p-1">
                        <div class="col-12">
                            <p class="m-0">
                                Precios con IVA:
                            </p>
                        </div>
                        <div class="col-12">
                            <ki-input label="Precio mayorista:"
                                type="number"
                                v-model="price_with_iva"
                                placeholder="00:00"
                                :disabled="true"
                                full>
                            </ki-input>
                        </div>
                        <div class="col-12">
                            <ki-input label="Precio minorista"
                                type="number"
                                placeholder="00:00"
                                v-model="price_minor_with_iva"
                                :disabled="true"
                                full>
                            </ki-input>
                        </div>
                    </div>
                </div>

                <div class="col-2 border p-0 m-2">
                    <div class="row p-1">
                        <div class="col-12">
                            <ki-input @input="updatePrice('cost', unit_cost)"
                                label="Costo:"
                                type="number"
                                v-model="unit_cost"
                                placeholder="00:00"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.unit_cost">
                                {{ errors.unit_cost}}
                            </span>
                        </div>
                        <div class="col-12">
                            <ki-input label="Costo con IVA:"
                                v-model="unit_cost_with_iva"
                                type="number"
                                placeholder="00:00"
                                :disabled="true"
                                full>
                            </ki-input>
                        </div>
                        <div class="col-12">
                            <ki-input label="Código de Barras:"
                                type="number"
                                v-model="barcode"
                                placeholder="ingrese código..."
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.barcode">
                                {{ errors.barcode }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-2 border p-0 m-2">
                    <div class="row p-1">
                        <div class="col-12">
                            <ki-select @change="provider_code = provider"
                                label="Proveedor:"
                                :options="provider_options"
                                v-model="provider"
                                full>
                            </ki-select>
                            <span class="text-danger" v-if="errors.provider">
                                {{ errors.provider }}
                            </span>
                        </div>
                        <div class="col-12">
                            <ki-input @input="provider = provider_code"
                                label="Código del proveedor:"
                                type="text"
                                v-model="provider_code"
                                placeholder="Ingrese código"
                                full>
                            </ki-input>
                        </div>
                    </div>
                </div>

                <div class="col-2 border p-2 m-2">
                    <div class="row p-0">
                        <div class="col-12">
                            <ki-input label="Tamaño:" 
                                placeholder="EJ: XL"
                                v-model="size"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.size">
                                {{ errors.size }}
                            </span>
                        </div>
                        <div class="col-12">
                            <ki-input label="Marca:" 
                                placeholder="EJ: levi"
                                v-model="brand"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.brand">
                                {{ errors.brand }}
                            </span>
                        </div>
                        <div class="col-12">
                            <ki-input label="Material:" 
                                placeholder="EJ: Algodón"
                                v-model="material"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.material">
                                {{ errors.material }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-2 border p-0 m-2">
                    <div class="row p-1">
                        <div class="col-12">
                            <ki-input label="Color:" 
                                placeholder="EJ: Blanco"
                                v-model="color"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.material">
                                {{ errors.material }}
                            </span>
                        </div>
                        <div class="col-12">
                            <ki-input label="Genero:" 
                                placeholder="EJ: Dama, Caballero, etc."
                                v-model="genre"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.genre">
                                {{ errors.genre }}
                            </span>
                        </div>
                        <div class="col-12">
                            <ki-input label="Modelo:" 
                                placeholder="EJ: 2344533TF"
                                v-model="product_model"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.product_model">
                                {{ errors.product_model }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-2 border p-0 m-2">
                    <div class="row p-1">
                        <div class="col-12">
                            <ki-input label="Unidades:" 
                                placeholder="EJ: piezas, cajas"
                                v-model="product_units"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.product_units">
                                {{ errors.product_units }}
                            </span>
                        </div>
                        <!-- <div class="col-12">
                            <ki-input label="Cantidad:" 
                                placeholder="EJ: 10"
                                v-model="product_quantity"
                                type="number"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.product_quantity">
                                {{ errors.product_quantity }}
                            </span>
                        </div> -->
                    </div>
                </div>


                <div class="col-12 py-2 d-flex justify-content-end">
                    <button @click="cancel"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="saveProduct"
                        class="btn btn-primary pills text-white ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>

            </div>
        </ki-modal>

        <!--delete modal-->
        <ki-modal v-if="delete_modal">
            <div class="row bg-white position-relative p-5" style="border-radius: 10px; max-width: 600px">
                <button @click="delete_modal = false; this.selected_id = null"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); top: -10px; right: -10px"
                    type="button">
                    X
                </button>
                <div class="col-12 opensans-bold text-center">
                    <p>
                        ¿Esta seguro de borrar este producto?
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button @click="delete_modal = false; this.selected_id = null"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="deleteProduct"
                        class="btn btn-danger text-white ml-2 pills"
                        type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="delete_discount_modal">
            <div class="row bg-white position-relative p-5" style="border-radius: 10px; max-width: 400px">
                <button @click="delete_modal = false; this.selected_id = null"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); top: -10px; right: -10px"
                    type="button">
                    X
                </button>
                <div class="col-12 opensans-bold text-center">
                    <p>
                        ¿Esta seguro de borrar este producto?
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button @click="delete_discount_modal = false; this.selected_id = null"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="processDeleteDiscount"
                        class="btn btn-danger text-white ml-2 pills"
                        type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!--percentaje modal-->
        <ki-modal v-if="product_discount_modal">
            <div class="row bg-white position-relative" 
                style="border-radius: 10px">
                <button @click="cancel"
                    class="btn btn-white position-absolute p-0"
                    style="top: -10px; right: -10px; width: 40px; height: 40px; box-shadow: -5px 5px 15px rgba(0,0,0, 0.2)"
                    type="button">
                    X
                </button>
                <div class="col-12 pt-2">
                    <p>
                        Administrar descuentos
                    </p>
                </div>

                <div class="col-6 pt-3">
                    <div class="row">
                        <div class="col-4 p-0">
                            <p>
                                Descuento a aplicar:
                            </p>
                        </div>
                        <div class="col-6 p-0">
                            <ki-select 
                                :options="discount_options"
                                :hidden_option="'Seleccione...'"
                                v-model="selected_discount"
                                full>
                            </ki-select>
                            <span class="text-danger" v-if="error_discount_selected">
                                {{ error_discount_selected }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12"
                    style="max-height: 400px; overflow-y: scroll">
                    <ki-table :data="product_discount_table_data" 
                        :allow_headers="product_discount_table_headers"
                        color_headers="white" color_body="white" no_pagination>
                        <template v-slot:seleccion="data">
                            <button @click.stop="deselectItem(data.item)"
                                class="btn btn-danger text-white p-0"
                                style="width: 25px; height: 25px; margin-bottom: 15px"
                                type="button">
                                X
                            </button>
                        </template>
                    </ki-table>
                </div>

                <div class="col-12 py-3 d-flex justify-content-end">
                    <button @click="cancel"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="saveProductsDiscount"
                        class="btn btn-primary pills text-white ml-3"
                        type="button"
                        :disabled="pending">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //icons
    import add_icon from 'ASSETS/icons/plus-blue-icon.png'
    import write_icon from 'ASSETS/icons/icon_write.png'
    import delete_icon from 'ASSETS/icons/icon_delete_red.png'
    import { mapGetters } from 'vuex';


    export default {
        data(){
            return {
                add_icon,
                write_icon,
                delete_icon,
                search_query: '',
                search_options: [
                    { id: 1, label: 'Nombre de producto'},
                    { id: 2, label: 'Precio'},
                    { id: 3, label: 'Código de barras'},
                ],
                delete_modal: false,
                open_modal: false,
                loader: true,
                product_data_headers: [
                    { key: 'seleccion', width: 80 },
                    { key:'product_name', label: 'Nombre' },
                    //{key: 'id_vendor', label: 'Código de proveedor' },
                    { key: 'unit_cost', label: 'Costo' },
                    { key: 'sell_price', label: 'Precio Mayoreo' },
                    { key: 'retail_price', label: 'Precio Menudeo' },
                    { key:'upc', label: 'Co. Barras' },
                    // {key:'size', label:'Talla:' },
                    // {key: 'brand', label: 'Marca:' },
                    // {key: 'color', label: 'Color:' },
                    // {key: 'product_description', label: 'Descripción' },
                    // {key: 'model', label:'Modelo' },
                    { key: 'Editar', label: 'Editar' },
                    { key: 'Borrar', label: 'Borrar' }
                ],
                product_data_table: [],
                provider_options: null,
                distributor_center_options: null,
                subcategory_options: null,
                product_name: null,
                distributor_center: null,
                subcategory: null,
                price: null,
                price_minor: null,
                pending: false,
                price_with_iva: null,
                price_minor_with_iva: null,
                unit_cost: null,
                unit_cost_with_iva: null,
                barcode: null,
                provider: null,
                provider_code: null,
                size: null,
                brand: null,
                material: null,
                color: null,
                genre: null,
                product_model: null,
                product_units: null,
                product_quantity: null,
                product_description: null,
                selected_id: null,
                selected_discount: 0,
                selected_products: [],
                discount_options: [],
                product_discount_modal: false,
                errors: {
                    product_name: null,
                    distributor_center: null,
                    subcategory: null,
                    price: null,
                    price_minor: null,
                    unit_cost: null,
                    barcode: null,
                    provider_code: null,
                    size: null,
                    brand: null,
                    material: null,
                    color: null,
                    genre: null,
                    product_model: null,
                    product_units: null,
                    product_quantity: null,
                    product_description: null,
                    provider: null
                },
                error_discount_selected: null,
                discount_filter: null,
                filtered: null,

                //set discount modal table
                product_discount_table_headers: [
                    { key: 'product_name', label: 'Producto'},
                    //{ key: 'sell_price', label: 'Precio mayoreo'},
                    //{ key: 'unit_cost', label: 'Costo'},
                    //{ key: 'retail_price', label: 'Precio menudeo'},
                    { key: 'seleccion'}
                    //{ key: 'description', label: 'Descripción'}
                ],
                product_discount_table_data: [],

                //products discount table
                products_by_discount_table_headers: [
                    {key: 'seleccion'},
                    {key: 'product_name', label: 'Nombre'},
                    {key: 'discount_description', label: 'Tipo de descuento'},
                    {key: 'discount_pct', label: 'Porcentaje'},
                    {key: 'borrar'}
                ],
                products_by_discount_table_data: [],
                delete_discount_modal: false,
                
                //paginator
                current_page: 1,
                rows: 10,
            };
        },
        computed: {
            ...mapGetters({
                getProviders: 'providers/getProviders',
                getWarehouses: 'warehouse/getWarehouses',
                getSubcategories: 'subcategory/getSubcategories',
                getDiscounts: 'discounts/getDiscounts',
                getErrorProviders: 'providers/getError',
                getErrorWarehouse: 'warehouse/getError',
                getErrorSubcategories: 'subcategory/getError',
                getProducts: 'products_module/getProducts',
                getProduct: 'products_module/getProduct',
                get_products_discount: 'products_discounts_module/getProductDiscounts',
                total_pages: 'products_module/getTotalPages',
                total_rows: 'products_module/getTotalRows'
            }),
            findProvider() {
                let finded_provider = this.provider_options.filter(provider => provider.id == this.provider_code)
                return finded_provider[0].label
            }
        },
        methods: {
            cancel () {
                this.open_modal = false

                this.product_name =  null
                this.distributor_center =  null
                this.subcategory =  null
                this.price =  null
                this.price_minor =  null
                this.price_with_iva =  null
                this.price_minor_with_iva =  null
                this.unit_cost =  null
                this.unit_cost_with_iva = null
                this.barcode =  null
                this.provider =  null
                this.provider_code =  null
                this.size =  null
                this.brand =  null
                this.material =  null
                this.color =  null
                this.genre =  null
                this.product_model =  null
                this.product_units =  null
                this.product_quantity = null
                this.product_description = null
                this.selected_id = null

                this.selected_products = []
                this.product_discount_modal = false
                this.selected_discount = 0

                for(let error in this.errors) {
                    this.errors[error] = null
                }

            },
            calculateIVA(number) {
                
                if(typeof(number) == 'string') {
                    number = Number(number)
                }

                let percent = this.formatAmount(number, 2) * 0.16
                let number_plus_IVA = this.formatAmount(percent + number, 2)

                return number_plus_IVA
            },
            updatePrice(type, price){
                switch(type){
                    case 'sell':
                        this.price_with_iva = this.calculateIVA(price)
                        break;
                    case 'retail':
                        this.price_minor_with_iva = this.calculateIVA(price)
                        break;
                    case 'cost':
                        this.unit_cost_with_iva = this.calculateIVA(price)
                        break;
                }
            },
            updateDiscountProductsTable() {

                this.product_discount_table_data = this.getProducts.map( product => {
                    return { id: product.id_product, product_name: product.product_name, price: product.sell_price, description: product.product_description}
                })
            },
            async saveProduct() {
                //error refreshing
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                //validations
                let is_complete = true
                
                if(_.isNull(this.product_name) || _.isEmpty(this.product_name)) {
                    is_complete = false
                    this.errors.product_name = '*Obligatorio'
                }

                if(_.isNull(this.price)) {
                    is_complete = false
                    this.errors.price = '*Obligatorio'
                }

                if(_.isNull(this.price_minor)) {
                    is_complete = false
                    this.errors.price_minor = '*Obligatorio'
                }

                if(_.isNull(this.unit_cost)) {
                    is_complete = false
                    this.errors.unit_cost = '*Obligatorio'
                }

                if(_.isNull(this.provider)) {
                    is_complete = false
                    this.errors.provider = '*Obligatorio'
                }


                if(is_complete) {
                    
                    const payload = {
                        id_vendor: this.provider,
                        id_subcategory: this.subcategory,
                        product_name: this.product_name,
                        unit_cost: this.unit_cost,
                        sell_price: this.price,
                        retail_price: this.price_minor,
                        upc: this.barcode,
                        size: this.size,
                        brand: this.brand,
                        color: this.color,
                        genre: this.genre,
                        model: this.product_model,
                        material: this.material,
                        product_description: this.product_description,
                        unit_measure: this.product_units
                    }

                    if(this.selected_id) {

                        let data = {
                            body: payload,
                            query: {
                                id_product: this.selected_id
                            }
                        }
                        await this.$store.dispatch('products_module/editProduct', data)
                        await this.navigate()

                        this.updateDiscountProductsTable()
                        this.cancel()
                        return
                    }

                    else {
                        await this.$store.dispatch('products_module/insertProduct', payload)
                        await this.navigate()

                        this.updateDiscountProductsTable()
                        this.cancel()
                    }
                }
     
            },
            async selectEditProduct(item) {
                this.selected_id = item.item.id_product
                await this.$store.dispatch('products_module/viewProduct', { id_product: this.selected_id})
                
                this.brand = this.getProduct.brand
                this.color = this.getProduct.color
                this.genre = this.getProduct.genre
                this.distributor_center = this.getProduct.id_distribution_center
                this.subcategory = this.getProduct.id_sub_category
                this.provider = this.getProduct.id_vendor
                this.provider_code = this.getProduct.id_vendor
                this.product_quantity = parseInt(this.getProduct.inventory_qty)
                this.material = this.getProduct.material
                this.product_model = this.getProduct.model
                this.product_description = this.getProduct.product_description
                this.product_name = this.getProduct.product_name
                this.price_minor = this.formatAmount(this.getProduct.retail_price,2)
                this.price = this.formatAmount(this.getProduct.sell_price, 2)
                this.size = this.getProduct.size
                this.unit_cost = this.formatAmount(this.getProduct.unit_cost, 2)
                this.product_units = this.getProduct.unit_measure
                this.barcode = parseInt(this.getProduct.upc)

                this.updatePrice('retail', this.formatAmount(this.getProduct.retail_price, 2))
                this.updatePrice('sell', this.formatAmount(this.getProduct.sell_price, 2))
                this.updatePrice('cost', this.formatAmount(this.getProduct.unit_cost, 2))
                this.open_modal = true

            },
            selectDeleteProduct(item){
                this.selected_id = item.item.id_product
                this.delete_modal = true
            },
            async deleteProduct() {
                await this.$store.dispatch('products_module/deleteProduct', { id_product: this.selected_id})
                await this.$store.dispatch('products_module/listProducts', {datatable: true, page: this.current_page, rows: this.rows})
                this.composeTableObject()
                this.updateDiscountProductsTable()
                this.cancel()
                this.delete_modal = false
            },
            search() {
                this.navigate();
            },
            setSelectedProducts(data) {
                if(this.selected_products.includes(data.item.id)) {
                    this.selected_products = this.selected_products.filter( id => id !== data.item.id)    
                }
                
                else {
                    this.selected_products.push(data.item.id)
                }
                
            },
            composeTableObject() {
                this.product_data_table = this.getProducts.map( product => {
                    return { 
                        checked: false, 
                        seleccion: '-', 
                        ...product
                        }
                })

                for(let product in this.product_data_table){
                        for(let selected_product in this.product_discount_table_data) {
                            if((this.product_data_table[product].id_product == this.product_discount_table_data[selected_product].id_product) && (this.product_data_table[product].product_name == this.product_discount_table_data[selected_product].product_name)) {
                                this.product_data_table[product].checked = true
                            }
                        }
                    }
            },

            //Paginator
            async navigate(){
                this.empty_search = false
                this.loading = true;

                if(this.current_page > this.total_pages){
                    this.current_page = this.total_pages == 0 ? 1 : this.current_page;
                }

                await this.$store.dispatch('products_module/listProducts', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
                
                if(!this.getProducts) {
                    this.empty_search = true;
                    return
                }

                else {
                    this.composeTableObject()
                }

                this.loading = false;
                
            },
            prev(){
                this.current_page--;
                this.navigate()
            },
            next(){
                this.current_page++;
                this.navigate()
            },
            
            //product discount
            selectedItem(data, flag) {

                switch(flag) {
                    case 'P_TABLE':
                        if(this.product_discount_table_data.includes(data)) {
                            
                            let origin_index = this.product_data_table.filter( product => product.id_product == data.id_product)
                            origin_index = this.product_data_table.indexOf(origin_index[0])
                            this.product_data_table[origin_index].checked = false
                        
                            let index = this.product_discount_table_data.indexOf(data)
                            delete this.product_discount_table_data[index]
                            this.product_discount_table_data = this.product_discount_table_data.filter(product => true)
                            
                        } 
                        else {
                            let item = this.product_data_table.find( product => product.id_product == data.id_product)
                            let index = this.product_data_table.indexOf(item)

                            this.product_data_table[index].checked = true
                            this.product_discount_table_data.push(data)
                        }
                        return;
                    case 'P_DISCOUNT':
                        let exist;
                        for(let product in this.product_discount_table_data) {
                            if(this.product_discount_table_data[product].id_product == data.id_product) {
                                exist = true
                            }
                        }

                        if(exist) {
                            for(let product in this.product_discount_table_data) {
                                if(data.id_product == this.product_discount_table_data[product].id_product) {
                                    delete this.product_discount_table_data[product]
                                }
                            }

                            this.product_discount_table_data = this.product_discount_table_data.filter( product => true)
                        }
                        else {
                            this.product_discount_table_data.push(data)
                        }
                        return;
                    default:
                        console.log('NOT MATCH');
                        return
                }
                
            },
            deselectItem(data) {

                if(this.product_data_table.length > 0) {
                   
                    let root_index = this.product_data_table.indexOf(data)
                    let selected_index = this.product_discount_table_data.indexOf(data)
                    delete this.product_discount_table_data[selected_index]
                    this.product_discount_table_data = this.product_discount_table_data.filter( product => true)

                    if(this.product_data_table[root_index]) {
                        this.product_data_table[root_index].checked = false
                    }
                    
                    if(this.product_discount_table_data.length == 0) {
                        this.product_discount_modal = false
                    }
                }

                else {
                    
                    let root_index = this.products_by_discount_table_data.indexOf(data)
                    let selected_index = this.product_discount_table_data.indexOf(data)

                    delete this.product_discount_table_data[selected_index]
                    this.product_discount_table_data = this.product_discount_table_data.filter( product => true)

                    if(this.products_by_discount_table_data[root_index]) {
                        this.products_by_discount_table_data[root_index].checked = false
                    }
                    

                    if(this.product_discount_table_data.length == 0) {
                        this.product_discount_modal = false
                    }
                }
                

            },
            async filterByDiscount(){
              
                if(this.discount_filter == 0) {
                    
                    this.product_data_table = this.getProducts
                    this.composeTableObject()
                    this.products_by_discount_table_data = []
                    this.filtered = false
                }

                else {
                    //console.log('DISCOUNT:', this.discount_filter)
                    this.filtered = true
                    this.product_data_table = []
                    this.products_by_discount_table_data = this.get_products_discount.filter( product => product.id_discount == this.discount_filter)
                    this.products_by_discount_table_data = this.products_by_discount_table_data.map( product => {
                        return  {
                            checked: product.checked || false,
                            seleccion: '-',
                            ...product,
                            borrar: '-'
                        }
                    })

                    for(let product in this.products_by_discount_table_data){
                        for(let selected_product in this.product_discount_table_data) {
                            if(this.products_by_discount_table_data[product].id_product == this.product_discount_table_data[selected_product].id_product) {
                                this.products_by_discount_table_data[product].checked = true
                            }

                            else {
                                console.log('doesnt match')
                            }
                        }
                    }
                    
                }
            },
            async saveProductsDiscount() {
                this.error_discount_selected = ""
                let is_complete = true
                this.pending = true

                if(this.selected_discount == 0) {
                    this.error_discount_selected = '*Selecciona un descuento'
                    is_complete = false
                    return
                }

                else if(this.product_discount_table_data.length <= 0) {
                    this.error_discount_selected = '*No se han seleccionado productos'
                    is_complete = false
                    return
                }

                if(is_complete) {
                    
                    const id_products = this.product_discount_table_data.map( product => product.id_product)
                    
                   
                    const payload = {
                        products: id_products,
                        discount: Number(this.selected_discount)
                    }

                    await this.$store.dispatch('products_discounts_module/setDiscounts', payload)
                    
                    for(let item in this.product_data_table) {
                        this.product_data_table[item].checked = false
                    }

                    this.product_discount_table_data = []
                    this.pending = false

                    await this.$store.dispatch('products_discounts_module/listProductDiscounts')
                    this.products_by_discount_table_data = this.get_products_discount.filter( product => product.id_discount == this.discount_filter)
                    this.products_by_discount_table_data = this.products_by_discount_table_data.map( product => {
                        return  {
                            checked: product.checked || false,
                            seleccion: '-',
                            ...product
                        }
                    })
                    
                    this.cancel()
                }   
            },
            removeProductDiscount(data) {
                this.selected_id = data.item.id_product_discount
                this.delete_discount_modal = true
            },
            async processDeleteDiscount() {
                await this.$store.dispatch('products_discounts_module/deleteProductDiscount', { id_product_discount: this.selected_id})
                this.selected_id = null
                await this.$store.dispatch('products_discounts_module/listProductDiscounts')
                this.filterByDiscount()
                this.delete_discount_modal = false
            }
        },
        async mounted(){

            try {
                //providers options
                await this.$store.dispatch('providers/listProviders', {datatable: false})
                this.provider_options = this.getProviders.map(provider => { return { id: provider.id_vendor, label: provider.vendor_name}})
                
                //subcategories options
                await this.$store.dispatch('subcategory/listSubcategories', {datatable: false})
                this.subcategory_options = this.getSubcategories.map(subcategory => { return { id: subcategory.id_sub_category, label: subcategory.sub_category_name}})
                
                //warehouses(stores) options
                await this.$store.dispatch('warehouse/listWarehouses', {datatable: false})
                this.distributor_center_options = this.getWarehouses.map( warehouse => { return {id: warehouse.id_dc, label: warehouse.dc_name }})

                //discounts options
                await this.$store.dispatch('discounts/listDiscounts', {datatable: false})
                this.discount_options = this.getDiscounts.map( discount => { return {id: discount.id_discount, label: discount.discount_description }})

                // list products
                await this.navigate()
            
                //product discounts
                await this.$store.dispatch('products_discounts_module/listProductDiscounts')
                this.loader = false
            }
            catch(error) {
                console.error(error)
                this.product_data_table = []
                this.loader = false
            }
            
        }
    }

</script>