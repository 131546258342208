<template>
    <div id="config-discount" class="container-fluid">
        <div class="row pb-4">
            <div class="col-12 bg-white shadow-sm py-3 px-3 rounded border-box">
                <div class="row p-0">

                    <div class="col-12 px-0 py-3 bg-lightgray my-2">
                        <div class="row p-0">
                            <div class="col-6 col-xl-4 opensans-bold">
                                <ki-input @input="search"
	                                label="Buscar.."
                                    placeholder="Ej: promoción..."
                                    v-model="search_query"
                                    full>
                                </ki-input>
                            </div>
                            <div class="col-6 col-xl-4 py-2">
                                <button @click.stop="open_modal = true"
                                    class="btn btn-white pills">
                                    <img :src="add_icon"/>
                                    Agregar nuevo
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 py-3" v-if="loading">
                        <ki-loader></ki-loader>
                    </div>

                    <div class="col-12 text-dark" v-if="!loading && fetch_error">
                        <p>
                            Un error ha ocurrido intente más tarde
                        </p>
                    </div>

                    <div class="col-12 p-0 mt-2" v-if="!loading && !fetch_error">
                        <ki-table :data="data_table" 
                            :allow_headers="headers_allow" 
                            no_pagination color_headers="white" color_body="white">
                            <template v-slot:Editar="data">
                                <button @click.stop="selectDiscount('edit', data.item.id_discount)"
                                    class="btn btn-white pills p-0"
                                    style="width: 50px"
                                    type="button">
                                    <img :src="icon_write" />
                                </button>
                            </template>
                            <template v-slot:Borrar="data">
                                <button @click.stop="selectDiscount('delete', data.item.id_discount)"
                                    class="btn btn-white pills p-0"
                                    style="width: 50px"
                                    type="buttonss">
                                    <img :src="icon_delete_red" />
                                </button>
                            </template>
                        </ki-table>
                    </div>

                    <!-- Paginator -->
                    <div class="col-12 p-0 m-0 mt-2">
                        <div class="row justify-content-between align-items-center text-dark">
                            <div class="col d-flex">
                                <label class="mr-2">
                                    Elementos por página: 
                                </label>
                                <ki-input @keyup.enter="navigate"
                                    v-model="rows"
                                    :disabled="current_page > 1">
                                </ki-input>
                            </div>

                            <div class="col-1  d-flex justify-content-end p-0">
                                <button @click.stop="prev"
                                    class="btn btn-primary text-white pills"
                                    type="button"
                                    :disabled="current_page == 1">
                                    <b-icon icon="arrow-left"
                                        variant="white">
                                    </b-icon>
                                </button>
                            </div>

                            <div class="col-1" style="max-width: 80px;">
                                <ki-input @keyup.enter="navigate"
                                    v-model="current_page"
                                    full>
                                </ki-input>
                            </div>

                            <div class="col-1">
                                <p class="m-0">
                                    de {{  total_pages  }}
                                </p>
                            </div>

                            <div class="col-1 p-0" style="max-width: 65px">
                                <button @click.stop="next"
                                    class="btn btn-primary text-white pills"
                                    :disabled="current_page == total_pages"
                                    type="button">
                                    <b-icon icon="arrow-right"
                                        variant="white">
                                    </b-icon>
                                </button>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <ki-modal v-if="open_modal">
            <div class="row bg-white position-relative p-3" style="border-radius: 10px;">
                <button @click.stop="clearData"
                    class="btn btn-white position-absolute p-0"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,)"
                    type="button">
                    X
                </button>
                <div class="col-12 text-dark">
                    <p v-if="!selected_id">
                        Añadir un nuevo descuento
                    </p>
                    <p v-if="selected_id">
                        Editar un descuento
                    </p>
                </div>
                <div class="col-12">
                    <ki-input label="Descripcion:"
                        v-model="discount_description"
                        placeholder="Ej: Cliente frecuente..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.description" class="text-danger">
                        {{ errors.description }}
                    </span>
                </div>
                <div class="col-12">
                    <ki-input label="Porcentaje de descuento"
                        v-model="discount_percentaje"
                        placeholder="Ej:25"
                        type="number"
                        full>
                    </ki-input>
                    <span v-if="errors.percentaje" class="text-danger">
                        {{ errors.percentaje }}
                    </span>
                </div>
                <div class="col-12 pt-4 d-flex justify-content-end">
                    <button @click.stop="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="createUpdateDiscount"
                        class="btn btn-primary pills text-white ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="delete_modal">
            <div class="row bg-white position-relative" style="border-radius: 10px">
                <button @click.stop="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                    type="button">
                    X
                </button>
                <div class="col-12 text-center text-dark p-3 opensans-bold">
                    <p>
                        ¿Esta seguro de borrar este descuento?
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-center p-3">
                    <button @click.stop="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="deleteDiscount"
                        class="btn btn-danger pills ml-2 text-white"
                        type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //Icons
    import icon_write from 'ASSETS/icons/icon_write.png';
    import icon_delete_red from 'ASSETS/icons/icon_delete_red.png'; 
    import add_icon from 'ASSETS/icons/plus-blue-icon.png'

    //helpers
    import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            title_discount: [
                { name: 'Configuración de descuento', selected: true },
            ],
            titletab_active: null,
            headers_allow: [
                { key: 'discount_description', label: "Descripción" }, 
                { key: 'discount_pct', label: "Descuento %", width: '120' }, 
                { key: 'Editar', label: "Editar", width: '60' }, 
                { key: 'Borrar', label: "Borrar", width: '60' }, 
            ],
            data_table: [],
            icon_write,
            icon_delete_red,
            add_icon,
            loading: true,
            fetch_error: null,
            open_modal: false,
            delete_modal: false,
            discount_description: null,
            discount_percentaje: null,
            selected_id: null,
            errors: {
                description: null,
                percentaje: null
            },
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            discounts: 'discounts/getDiscounts',
            discount: 'discounts/getDiscount',
            total_pages: 'discounts/getTotalPages',
            total_rows: 'discounts/getTotalRows'
        })
    },
    methods: {
        //helpers
        clearData(){
            this.open_modal = false
            this.discount_description = null
            this.discount_percentaje = null
            this.delete_modal = false
            this.selected_id = false
            
            for(let error in this.errors) {
                this.errors[error] = null
            }
        },
        async selectDiscount(type, id){
            switch(type){
                case 'edit':
                    this.selected_id = id
                    await this.$store.dispatch('discounts/viewDiscount', { id_discount: this.selected_id})
                    this.discount_description = this.discount.discount_description
                    this.discount_percentaje = Number(this.discount.discount_pct)
                    this.open_modal = true
                    break;
                case 'delete':
                    this.selected_id = id
                    this.delete_modal = true
                    break;
            }
        },
        //discounts CRUD
        async createUpdateDiscount() {
            //error refreshing
            for(let error in this.errors) {
                this.errors[error] = null
            }

            let is_complete = true

            if(_.isNull(this.discount_description) || _.isEmpty(this.discount_description)) {
                is_complete = false
                this.errors.description = '*Campo obligatorio'
            }

            if(_.isNull(this.discount_percentaje) || !_.isNumber(Number(this.discount_percentaje))) {
                is_complete = false
                this.errors.percentaje = '*Campo obligatorio'
            }


            if(is_complete) {
                const payload = {
                    percent: this.discount_percentaje,
                    description: this.discount_description
                }

                if(this.selected_id) {
                    payload.id_discount = this.selected_id

                    let data = {
                        body: payload,
                        query: {
                            id_discount: this.selected_id
                        }
                    }
                    await this.$store.dispatch('discounts/editDiscount', data)
                    await this.navigate()
                    this.clearData()
                    return
                } else {
                    await this.$store.dispatch('discounts/insertDiscount', payload)
                    await this.navigate()
                    this.clearData()
                }
            }
        },
        async deleteDiscount() {
            await this.$store.dispatch('discounts/deleteDiscount', { id_discount: this.selected_id})
            await this.navigate()
            this.clearData()
        },
	    async search() {
			await this.navigate();
	    },
        //paginator
        async navigate(){
            this.empty_search = false
            this.loading = true

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }

            
            await this.$store.dispatch('discounts/listDiscounts', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
            
                    
            if(!this.discounts) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.discounts
            }
                    
            this.loading = false
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }

    },
    async mounted() {
        try {
            //list of discounts
            await this.navigate()
            this.fetch_error = false
        }

        catch(e) {
            this.fetch_error = true
            this.loading = false
        }
        
    }
};
</script>