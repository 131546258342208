<template>
    <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box px-4">

        <div class="col-12 p-0">
            <div class="row bg-lightgray p-3 opensans-bold my-3">

                <div class="col-6 col-xl-3 p-0">
                    <ki-input @input="searchProvider"
                        label="Buscar proveedor" 
                        type="text" 
                        v-model="search_query"
                        placeholder="Ingrese su busqueda" 
                        class="opensans-bold pt-1"
                        full>
                    </ki-input>
                </div>

                

                <div class="col-12 col-xl-3  pl-0 pl-xl-1 pt-2 pt-xl-4" style="max-width: 220px">
                    <button @click="open_modal = true"
                        class="pills bg-white py-1 w-100"
                        type="button">
                        <img :src="add_icon" />
                        Agregar proveedor
                    </button>
                </div>

            </div>
        </div>

        <div class="col-12 py-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div class="col-12 opensans-bold text-dark" v-if="fetch_error">
            <p>
                Un error ha ocurrido intente más tarde
            </p>
        </div>

        <div class="col-12 p-0 mt-2 mb-2" style="border: solid 1px #DCDDE0;" v-if="!loading && !fetch_error">
            <ki-table :data="data_table"
                :allow_headers="headers_allow"
                no_pagination color_headers="white" color_body="white">

                <template v-slot:Editar="data">
                    <button @click="selectProvider('edit', data.item.id_vendor)"
                        class="pills bg-white p-0"
                        style="width: 50px"
                        type="button">
                        <img :src="icon_write" />
                    </button>
                </template>

                <template v-slot:Borrar="data">
                    <button @click="selectProvider('delete', data.item.id_vendor)"
                        class="pills bg-white p-0"
                        style="width: 50px"
                        type="button">
                        <img :src="icon_delete_red" />
                    </button>
                </template>

            </ki-table>
        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página: 
                    </label>
                    <ki-input @keyup.enter="navigate"
                        v-model="rows"
                        :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                        class="btn btn-primary text-white pills"
                        type="button"
                        :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                        v-model="current_page"
                        full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                        class="btn btn-primary text-white pills"
                        :disabled="current_page == total_pages"
                        type="button">
                        <b-icon icon="arrow-right"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <!---Edit, create modal-->
        <ki-modal v-if="open_modal">
            <div class="row bg-white text-dark position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="right: -10px; top:-10px; width: 25px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                    type="button">
                    X
                </button>
                <div class="col-12 pt-3 opensans-bold">
                    <p>
                        Registrar nuevo proveedor
                    </p>
                </div>
                <div class="col-6">
                    <ki-input v-model="provider_name"
                        label="Nombre del Proveedor"
                        placeholder="Añadir nombre de proveedor..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.name" class="text-danger">
                        {{ errors.name }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="provider_email"
                        label="Correo electrónico"
                        placeholder="email..."
                        type="email"
                        full>
                    </ki-input>
                    <span v-if="errors.email" class="text-danger">
                        {{ errors.email }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="provider_contact_manager"
                        label="Gerente"
                        placeholder="Añadir nombre del gerente..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.manager" class="text-danger">
                        {{ errors.manager }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="provider_street"
                        label="Dirección"
                        placeholder="Dirección del proveedor..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.street" class="text-danger">
                        {{ errors.street }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="provider_state"
                        label="Estado"
                        placeholder="Estado del proveedor..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.state" class="text-danger">
                        {{ errors.state }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="provider_zip_code"
                        label="Código Postal/ Código ZIP"
                        placeholder="Código del proveedor..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.zip_code" class="text-danger">
                        {{ errors.zip_code }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="provider_phone"
                        label="Teléfono"
                        placeholder="Teléfono del proveedor..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.phone" class="text-danger">
                        {{ errors.phone }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="provider_country"
                        label="País"
                        placeholder="País del proveedor..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.country" class="text-danger">
                        {{ errors.country }}
                    </span>
                </div>
                <div class="col-12 p-3 d-flex justify-content-end">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="createUpdateProvider"
                        class="btn btn-primary pills text-white ml-3"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!--Delete modal-->
        <ki-modal v-if="show_delete_modal">
            <div class="row bg-white justify-content-center p-4 position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                    class="position-absolute btn btn-white p-0"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2);"
                    type="button">
                    X
                </button>

                <div class="col-12 text-black text-center opensans-bold">
                    <p>
                        ¿Está seguro de borrar este proveedor?
                    </p>
                </div>
                
                <div class="col-12 d-flex justify-content-center">
                    <button @click="deleteProvider"
                        class="btn btn-danger pills text-white"
                        type="button">
                        Borrar
                    </button>
                    <button @click="clearData"
                        class="btn btn-white pills ml-4"
                        type="button">
                        Cancelar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //Icons
    import icon_write from '../../../assets/icons/icon_write.png';
    import icon_delete_red from '../../../assets/icons/icon_delete_red.png'; 
    import add_icon from 'ASSETS/icons/plus-blue-icon.png'

    //helpers
    import { mapGetters } from 'vuex'

export default {
    name: 'ki-providers',

    data() {
        return {
            icon_write,
            icon_delete_red,
            add_icon,
            open_modal: false,
            show_delete_modal: false,
            loading: true,
            fetch_error: false,
            headers_allow: [
                {key: 'vendor_name', label: 'Nombre'},
                //{key: 'days', label: 'Dias de crédito', width: 200},
                {key: 'vendor_phone', label: 'Teléfono'},
                {key: 'vendor_email', label: 'Correo'},
                {key: 'vendor_street', label: 'Dirección'},
                {key: 'vendor_contact_manager', label:'Encargado'},
                {key: 'Editar', label: 'Editar'},
                {key: 'Borrar', label: 'Borrar'}
            ],
            data_table: [],
            search_type: '1',
            search_options: [
                { id: 1, label:'Nombre del proveedor'},
                { id: 2, label: 'Correo del proveedor'},
                { id: 3, label: 'Nombre del encargado'},
                { id: 4, label: 'Númeo de teléfono/celular'}
            ],
            selected_id: null,
            provider_name: null,
	        provider_email: null,
	        provider_contact_manager: null,
	        provider_street: null,
	        provider_state: null,
	        provider_zip_code: null,
	        provider_phone: null,
	        provider_country: null,
            errors: {
                name: null,
                email: null,
                manager: null,
                street: null,
                state: null,
                zip_code: null,
                phone: null,
                country: null
            },
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            providers: 'providers/getProviders',
            provider: 'providers/getProvider',
            total_pages: 'providers/getTotalPages',
            total_rows: 'providers/getTotalRows'
        })
    },
    methods: {
        //helpers
        clearData(){
            this.selected_id = null
            this.provider_name = null
            this.provider_email = null
            this.provider_street = null
            this.provider_contact_manager = null
            this.provider_phone = null
            this.provider_country = null
            this.provider_zip_code = null
            this.provider_state = null
            this.open_modal = false
            this.show_delete_modal = false

            for(let error in this.errors){
                this.errors[error] = null
            }

        },
        isEmail (value) {
            let exp_reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

            if(exp_reg.test(value)) {
                return true
            }
            else {
                return false
            }
        },
        async selectProvider(type, id) {
            switch(type){
                case 'edit':
                    this.selected_id = id
                    await this.$store.dispatch('providers/viewProvider', { provider_id: this.selected_id})
                    this.provider_contact_manager = this.provider.vendor_contact_manager
                    this.provider_country = this.provider.vendor_country
                    this.provider_email = this.provider.vendor_email
                    this.provider_name = this.provider.vendor_name
                    this.provider_phone = this.provider.vendor_phone
                    this.provider_state = this.provider.vendor_state
                    this.provider_street = this.provider.vendor_street
                    this.provider_zip_code = new String(this.provider.vendor_zip_code)
                    this.open_modal = true
                    break;
                case 'delete':
                    this.selected_id = id
                    this.show_delete_modal = true
                    break;
            }
        },

        //providers CRUD
        async createUpdateProvider () {
            //seteo de errores
            for(let error in this.errors) {
                this.errors[error] = null
            }

            let is_complete = true

            if(_.isNull(this.provider_name) || _.isEmpty(this.provider_name)) {
                this.errors.name = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.provider_email) || _.isEmpty(this.provider_email)) {
                this.errors.email = '*Campo obligatorio'
                is_complete = false
            }

            else if(!this.isEmail(this.provider_email)) {
                is_complete = false
                this.errors.email = 'Correo no valido'
            }

            if(_.isNull(this.provider_contact_manager) || _.isEmpty(this.provider_contact_manager)) {
                this.errors.manager= '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.provider_street) || _.isEmpty(this.provider_street)) {
                this.errors.street = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.provider_state) || _.isEmpty(this.provider_state)) {
                this.errors.state = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.provider_zip_code) || _.isEmpty(this.provider_zip_code)) {
                this.errors.zip_code = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.provider_phone) || _.isEmpty(this.provider_phone)) {
                this.errors.phone = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.provider_country) || _.isEmpty(this.provider_country)) {
                this.errors.country = '*Campo obligatorio'
                is_complete = false
                return
            }

            if(is_complete) {
                const payload = {
                    vendor_name: this.provider_name,
                    vendor_email: this.provider_email,
                    vendor_street: this.provider_street,
                    vendor_contact_manager: this.provider_contact_manager,
                    vendor_phone: this.provider_phone,
                    vendor_country: this.provider_country,
                    vendor_zip_code: this.provider_zip_code,
                    vendor_state: this.provider_state
                }


                if(this.selected_id) {
    
                    let data = {
                        body: payload,
                        query: {
                            id_vendor: this.selected_id
                        }
                    }

                    await this.$store.dispatch('providers/editProvider', data)
                    await this.navigate()
                    this.open_modal = false
                    this.clearData()
                    return
                } 

                else {
                    await this.$store.dispatch('providers/insertProvider', payload)
                    await this.navigate()
                    this.open_modal = false
                    this.clearData()
                }
        }
        },
        async deleteProvider() {
            await this.$store.dispatch('providers/deleteProvider', { provider_id: this.selected_id})
            await this.navigate()
            this.clearData()
        },
        async searchProvider() {
            await this.navigate();
        },
        //paginator
        async navigate(){
            this.empty_search = false
            this.loading = true;

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }
			
	        await this.$store.dispatch('providers/listProviders', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
	        
            if(!this.providers) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.providers
            }
                
            this.loading = false
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async mounted() {
        try {

            //list of providers
            await this.navigate()
            this.fetch_error = false
        }

        catch(e) {
            console.error(e)
            this.loading = false
            this.fetch_error = true
        }
        
    }
};
</script>

<style lang="scss" scoped>

</style>