<template>
    <div id="config-branch" class="container-fluid mb-5" >
        <div class="row bg-white p-2">

            <div class="col-12 py-2">
                <div class="row bg-lightgray opensans-bold py-3 my-3">
                    <div class="col-6 col-xl-2 px-2">
                        <ki-input v-model="search_query"
                            @input="searchBranch" 
                            label="Buscar:" 
                            type="text" 
                            placeholder="Ingrese busqueda..." 
                            class="opensans-bold" 
                            full>
                        </ki-input>
                    </div>

                    <div class="col-6 col-xl-3 px-2 d-flex justify-content-start align-items-end">
                        <button @click="open_modal = true" 
                            class="pills bg-white" 
                            style="width: 200px;">
                            <img :src="icon_add" />
                            Agregar sucursal
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-12 py-3" v-if="loading">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12 text-dark" v-if="fetch_error">
                <p>
                    Un error ha ocurrido intente más tarde
                </p>
            </div>

            <div class="col-12 mb-3"  v-if="!loading && !fetch_error">
                <ki-table :data="data_table" 
                    :allow_headers="headers_allow" 
                    no_pagination color_headers="white" color_body="white">

                    <template v-slot:select="data">
                        <div class="pl-4">
                            <input type="radio" :checked="data.value">
                        </div>
                    </template>

                    <template v-slot:Editar="data">
                        <button @click="selectBranch('edit', data.item.id_store)"
                            id="btnEdit"
                            class="pills bg-white p-0"
                            style="width: 60px"
                            type="button">
                            <img :src="icon_write"  />
                        </button>
                    </template>

                    <template v-slot:Borrar="data">
                        <button @click="selectBranch('delete', data.item.id_store)"
                            id="btnDelete"
                            class="pills bg-white p-0"
                            style="width: 60px"
                            type="button">
                            <img :src="icon_delete_red" />
                        </button>
                    </template>
                </ki-table>
            </div>

            <!-- Paginator -->
            <div class="col-12 p-0 m-0 mt-2">
                <div class="row justify-content-between align-items-center text-dark">
                    <div class="col d-flex">
                        <label class="mr-2">
                            Elementos por página: 
                        </label>
                        <ki-input @keyup.enter="navigate"
                            v-model="rows"
                            :disabled="current_page > 1">
                        </ki-input>
                    </div>

                    <div class="col-1  d-flex justify-content-end p-0">
                        <button @click.stop="prev"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="current_page == 1">
                            <b-icon icon="arrow-left"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1" style="max-width: 80px;">
                        <ki-input @keyup.enter="navigate"
                            v-model="current_page"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-1">
                        <p class="m-0">
                            de {{  total_pages  }}
                        </p>
                    </div>

                    <div class="col-1 p-0" style="max-width: 65px">
                        <button @click.stop="next"
                            class="btn btn-primary text-white pills"
                            :disabled="current_page == total_pages"
                            type="button">
                            <b-icon icon="arrow-right"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                </div>
            </div>
              
        </div>

        
        
        <!--Create Edit modal-->
        <ki-modal v-if="open_modal">
            <div class="row bg-white text-dark position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="right: -10px; top:-10px; width: 25px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                    type="button">
                    X
                </button>

                <div class="col-12 pt-3 opensans-bold">
                    <p>
                        Registrar nueva sucursal
                    </p>
                </div>

                <div class="col-6">
                    <ki-input v-model="branch_name"
                        label="Nombre de la sucursal"
                        placeholder="Añadir nombre de sucursal..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.name" class="text-danger">
                        {{ errors.name }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input v-model="branch_email"
                        label="Correo electrónico"
                        placeholder="email..."
                        type="email"
                        full>
                    </ki-input>
                    <span v-if="errors.email" class="text-danger">
                        {{ errors.email }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input v-model="branch_manager"
                        label="Gerente"
                        placeholder="Añadir nombre del gerente..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.manager" class="text-danger">
                        {{ errors.manager }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input v-model="branch_street"
                        label="Dirección"
                        placeholder="Dirección de la sucursal..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.street" class="text-danger">
                        {{ errors.street }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input v-model="branch_state"
                        label="Estado"
                        placeholder="Estado de la sucursal..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.state" class="text-danger">
                        {{ errors.state }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input v-model="branch_zip_code"
                        label="Código Postal/ Código ZIP"
                        placeholder="Código de la sucursal..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.zip_code" class="text-danger">
                        {{ errors.zip_code }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input v-model="branch_phone"
                        label="Teléfono"
                        placeholder="Teléfono de la sucursal..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.phone" class="text-danger">
                        {{ errors.phone }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input v-model="branch_federal_tax"
                        label="RFC"
                        placeholder="RFC..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.branch_federal_tax" class="text-danger">
                        {{ errors.branch_federal_tax }}
                    </span>
                </div>

                <div class="col-12 p-3 d-flex justify-content-end">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="createUpdateBranch"
                        class="btn btn-primary pills text-white ml-3"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!--Delete modal-->
        <ki-modal v-if="show_delete_modal">
            <div class="row bg-white justify-content-center p-4 position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                    class="position-absolute btn btn-white p-0"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2);"
                    type="button">
                    X
                </button>

                <div class="col-12 text-black text-center opensans-bold">
                    <p>
                        ¿Está seguro de borrar esta sucursal?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click="deleteBranch"
                        class="btn btn-danger pills text-white"
                        type="button">
                        Borrar
                    </button>
                    <button @click="clearData"
                        class="btn btn-white pills ml-4"
                        type="button">
                        Cancelar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //Icons
    import icon_write from 'ASSETS/icons/icon_write.png';
    import icon_delete_red from 'ASSETS/icons/icon_delete_red.png'; 
    import icon_add from 'ASSETS/icons/plus-blue-icon.png'

    //helpers
    import { mapGetters } from 'vuex'

export default {
    name: 'ki-config-branch',
    data() {
        return {
            icon_write,
            icon_delete_red,
            icon_add,
            headers_allow: [
                { key: 'store_name', label: "Nombre", width: '80'}, 
                { key: 'store_manager', label: "Gerente", width: '80' }, 
                //{ key: 'store_email', label: "Correo", width:'80'}, 
                { key: 'store_street', label: "Calle", width: '80' }, 
                { key: 'store_zip_code', label: "C.P.", width: '80' }, 
                { key: 'Editar', label: "Editar", width: '20' }, 
                { key: 'Borrar', label: "Borrar", width: '20' }, 
            ],
            data_table: [],
            search_options: [
                { id: 1, label: 'Nombre de sucursal'},
                { id: 2, label: 'Correo de sucursal'},
                { id: 3, label: 'nombre del gerente'},
                { id: 4, label: 'Número de celular/Télefono'},
                { id: 5, label: 'Código ZIP'}
            ],
            search_type: '1',
            loading: true,
            fetch_error: false,
            open_modal: false,
            show_delete_modal: false,
            branch_name: null,
	        branch_email: null,
	        branch_manager: null,
	        branch_street: null,
	        branch_state: null,
	        branch_zip_code: null,
	        branch_phone: null,
	        branch_federal_tax: null,
            selected_id: null,
            errors: {
                name: null,
                email: null,
                manager: null,
                street: null,
                state: null,
                zip_code: null,
                phone: null,
                branch_federal_tax: null
            },
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            branches: 'branch/getBranches',
            branch: 'branch/getBranch',
            total_pages: 'branch/getTotalPages',
            total_rows: 'branch/getTotalRows'
        })
    },
    methods: {
        //helpers
        clearData(){
            this.open_modal = false
            this.show_delete_modal = false
            this.branch_name = null
	        this.branch_email = null
	        this.branch_manager = null
	        this.branch_street = null
	        this.branch_state = null
	        this.branch_zip_code = null
	        this.branch_phone = null
	        this.branch_federal_tax = null
            this.search_branch = null
            this.selected_id = null

            for(let error in this.errors){
                this.errors[error] = null
            }
        },
        isEmail (value) {
            let exp_reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

            if(exp_reg.test(value)) {
                return true
            }
            else {
                return false
            }
        },
        async selectBranch(type, id){
            switch(type){
                case 'edit':
                    this.selected_id = id
                    await this.$store.dispatch('branch/viewBranch', { id_store: this.selected_id})
                    this.branch_email = this.branch.store_email
                    this.branch_federal_tax = this.branch.store_federal_tax
                    this.branch_manager= this.branch.store_manager
                    this.branch_name = this.branch.store_name
                    this.branch_phone = this.branch.store_phone
                    this.branch_state = this.branch.store_state
                    this.branch_street = this.branch.store_street
                    this.branch_zip_code = this.branch.store_zip_code
                    this.open_modal = true
                    break;
                case 'delete':
                    this.selected_id = id
                    this.show_delete_modal = true
                    break;
            }
        },
        async createUpdateBranch () {
            //seteo de errores
            for(let error in this.errors) {
                this.errors[error] = null
            }

            let is_complete = true

            if(_.isNull(this.branch_name) || _.isEmpty(this.branch_name)) {
                this.errors.name = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.branch_email) || _.isEmpty(this.branch_email)) {
                this.errors.email = '*Campo obligatorio'
                is_complete = false
            }

            else if(!this.isEmail(this.branch_email)) {
                is_complete = false
                this.errors.email = 'Correo no valido'
            }

            if(_.isNull(this.branch_manager) || _.isEmpty(this.branch_manager)) {
                this.errors.manager= '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.branch_street) || _.isEmpty(this.branch_street)) {
                this.errors.street = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.branch_state) || _.isEmpty(this.branch_state)) {
                this.errors.state = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.branch_zip_code) || _.isEmpty(this.branch_zip_code)) {
                this.errors.zip_code = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.branch_phone) || _.isEmpty(this.branch_phone)) {
                this.errors.phone = '*Campo obligatorio'
                is_complete = false
            }

            else if(this.branch_phone.length !== 10) {
                this.errors.phone = '*10 digitos'
                is_complete = false
            }

            if(_.isNull(this.branch_federal_tax) || _.isEmpty(this.branch_federal_tax)) {
                this.errors.branch_federal_tax = '*Campo obligatorio'
                is_complete = false
                return
            }

            if(is_complete) {

                const payload = {
                    store_name: this.branch_name,
                    store_email: this.branch_email,
                    store_street: this.branch_street,
                    store_manager: this.branch_manager,
                    store_phone: this.branch_phone,
                    store_federal_tax: this.branch_federal_tax,
                    store_zip_code: this.branch_zip_code,
                    store_state: this.branch_state
                }

                if(this.selected_id) {

                    let data = {
                        body: payload,
                        query: {
                            id_store: this.selected_id
                        }
                    }

                    await this.$store.dispatch('branch/editBranch', data)
                    await this.navigate()
                    this.clearData()
                    return
                } 

                else {
                    await this.$store.dispatch('branch/insertBranch', payload)
                    await this.navigate()
                    this.clearData()
                }
            }
        },
        async deleteBranch(){
            await this.$store.dispatch('branch/deleteBranch', { id_store: this.selected_id} )
            await this.navigate();
            this.clearData()
        },
        
        async searchBranch() {
            
            await this.navigate();
        },
        //paginator
        async navigate(){
            this.empty_search = false
            this.loading = true

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }
	        
	        await this.$store.dispatch('branch/listBranches', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
	        
            if(!this.branches) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.branches
            }

            this.loading = false;
                    
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async mounted() {
        try {
            //lista de tiendas(stores)
            await this.navigate();
            this.fecth_error = false
        } 

        catch(e) {
            this.fecth_error = true
            this.loading = false
        }    
    }
};
</script>
<style lang="scss" scoped>
</style>