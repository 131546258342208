<template>
    <div id="Employee-Rols bg-white" class="opensans-bold text-dark mb-4">
        <div class="row">

            <div class="col-12 bg-white pb-3">
                <div class="row bg-lightgray py-3 my-3">

                    <div class="col-12 col-lg-6 ">
                        <ki-input @input="searchRole"
                            v-model="search_query"
                            label="Buscar:"
                            placeholder="Ej. ADMINISTRADOR"
                            type="text"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-12 col-lg-6  py-2 d-flex align-items-end">
                        <button @click="open_modal = true"
                            class="btn btn-white pills"
                            style="height: 40px;"
                            type="button">
                            <img :src="add_icon" />
                            Agregar
                        </button>

                        <button @click="view_access = true" v-if="!view_access"
                            class="btn btn-white pills ml-3"
                            style="height: 40px;"
                            type="button">
                            <img :src="add_icon" />
                            Permisos
                        </button>

                        <button @click="view_access = false" v-if="view_access"
                            class="btn btn-white pills ml-3"
                            style="height: 40px;"
                            type="button">
                            <img :src="add_icon" />
                            Roles
                        </button>
                    </div>

                </div>

                <div class="col-12 py-3" v-if="loading">
                    <ki-loader></ki-loader>
                </div>

                <div class="col-12" v-if="fetch_error">
                    <p>
                        Un error ha ocurrido intente más tarde
                    </p>
                </div>

                <div class="col-12 my-3 p-0" v-if="!loading && !fetch_error && !view_access">
                    <ki-table :data="user_roles_data"
                        :allow_headers="user_roles_headers"
                        no_pagination color_body="white" color_headers="white"
                        full>
                        <template v-slot:Editar="data">
                            <button @click="selectUserRole('edit', data.item.id_role)"
                                class="btn btn-white pills p-0"
                                style="width: 50px"
                                type="button">
                                <img :src="write_icon" />
                            </button>
                        </template>
                        <template v-slot:Borrar="data">
                            <button @click="selectUserRole('delete', data.item.id_role)"
                                class="btn btn-white pills p-0"
                                style="width: 50px"
                                type="button">
                                <img :src="delete_icon" />
                            </button>
                        </template>
                    </ki-table>
                </div>

                <div class="col-12 my-3 p-0" v-if="view_access">
                    <div class="row p-0 m-0">

                        <div class="col-4 pl-4">
                            <ki-select @change="viewAccess"
                                label="Rol de usuario:"
                                :options="user_role_options"
                                v-model="rol_id"
                                full>
                            </ki-select>
                        </div>

                        <div class="col-12 " v-for="(menu, index ) in permissions" :key="index">
                            <div class="row">

                                <div class="col-12">
                                    <p style="font-size: 18px;">
                                        {{ menu.name}}
                                    </p>
                                    <hr>
                                </div>

                                <div v-for="(subcategory, index) in menu.subcategories " :key="index" class="col-12">
                                    <div class="row">
                                        <div v-for="(modulo, index) in subcategory.modules" :key="`${index}`"
                                            :class="[`${ index % 2 == 0 ? 'border border-2 border-primary' : 'border-custom' }`]"
                                            class="col m-1"
                                            style="border-radius: 5px;">
                                            <p>
                                                {{ modulo.name}}
                                            </p>

                                            <div class="d-flex flex-column align-items-start">
                                                <ki-checkbox v-for="(action, index) in modulo.actions" :key="index"
                                                :label="action.label"
                                                v-model="action.has_permision"
                                                fill  />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>


                            </div>
                            
                            

                        </div>
                    </div>
                </div>

                <div class="col-12 p-0 m-0 mt-2">
                    <div class="row justify-content-between align-items-center text-dark">
                        <div class="col d-flex">
                            <label class="mr-2">
                                Elementos por página: 
                            </label>
                            <ki-input @keyup.enter="navigate"
                                v-model="rows"
                                :disabled="current_page > 1">
                            </ki-input>
                        </div>

                        <div class="col-1  d-flex justify-content-end p-0">
                            <button @click.stop="prev"
                                class="btn btn-primary text-white pills"
                                type="button"
                                :disabled="current_page == 1">
                                <b-icon icon="arrow-left"
                                    variant="white">
                                </b-icon>
                            </button>
                        </div>

                        <div class="col-1" style="max-width: 80px;">
                            <ki-input @keyup.enter="navigate"
                                v-model="current_page"
                                full>
                            </ki-input>
                        </div>

                        <div class="col-1">
                            <p class="m-0">
                                de {{  total_pages  }}
                            </p>
                        </div>

                        <div class="col-1 p-0" style="max-width: 65px">
                            <button @click.stop="next"
                                class="btn btn-primary text-white pills"
                                :disabled="current_page == total_pages"
                                type="button">
                                <b-icon icon="arrow-right"
                                    variant="white">
                                </b-icon>
                            </button>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>

        <!--Create edit modal-->
        <ki-modal v-if="open_modal">
            <div class="row opensans-bold text-dark bg-white p-3 position-relative" style="border-radius: 10px">
                <button @click="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); width: 30px; height: 30px"
                    type="button">
                    X
                </button>
                <div class="col-12 py-1" v-if="!selected_id">
                    <p class="m-0">
                        Añadir nuevo rol de usuario
                    </p>
                </div>

                <div class="col-12 py-1" v-if="selected_id">
                    <p class="m-0">
                        Editar rol de usuario
                    </p>
                </div>
                
                <div class="col-12 py-2">
                    <ki-input label="Rol de empleado"
                        v-model="user_role_name"
                        placeholder="Ingrese nombre del rol de empleado"
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="error_user_role_name" class="text-danger">
                        {{ error_user_role_name }}
                    </span>
                </div>

                <div class="col-12">
                    <hr>
                </div>
   
                <div class="col-12 d-flex justify-content-end">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="createUpdateUserRole"
                        class="btn btn-primary pills text-white ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!--Delete modal-->
        <ki-modal v-if="delete_modal">
            <div class="row opensans-bold bg-white py-3 position-relative" style="border-radius: 10px; max-width: 500px">
                <button @click="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2); width: 30px; height: 30px"
                    type="button">
                    X
                </button>

                <div class="col-12">
                    <p class="m-0 text-center opensans-bold">
                        ¿Esta seguro de eliminar este rol de empleado?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center my-3">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="deleteUserRole"
                        class="btn btn-danger text-white pills ml-2"
                        type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //icons
    import add_icon from 'ASSETS/icons/plus-blue-icon.png'
    import write_icon from 'ASSETS/icons/icon_write.png'
    import delete_icon from 'ASSETS/icons/icon_delete_red.png'
    

    //helpers
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                add_icon,
                write_icon,
                delete_icon,
                open_modal: false,
                delete_modal: false,
                loading: true,
                fetch_error: false,
                user_roles_headers: [
                    {key: 'role_name', label:"Rol de usuario"},
                    {key: 'Editar', label: 'Editar', width: '30'},
                    {key: 'Borrar', label: 'Borrar', width: '30'}
                ],
                user_roles_data: [],
                user_role_options: [],
                user_role_name: null,
                selected_id: null,
                rol_id: null,
                error_user_role_name: null,
                view_access: false,
                permissions: [
                    {
                        name: 'Catálogos',
                        subcategories: [
                            {
                                name: 'Ventas',
                                modules: [
                                    {
                                        name: 'Productos',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Dto. sobre producto',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Descuentos',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Formas de pago',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Clientes',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                ]
                            },
                            {
                                name: 'Inventarios',
                                modules: [
                                    {
                                        name: 'Categorias',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Subcategorias',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Proveedores',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Movimientos',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    }
                                ]
                            },
                            {
                                name: 'Sucursales',
                                modules: [
                                    {
                                        name: 'Sucursales',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Almacenes',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Motivos de merma',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    }
                                ]
                            },
                            {
                                name: 'Usuarios',
                                modules: [
                                    {
                                        name: 'Usuarios',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Empleados',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Roles de Usuario',
                                        actions: [
                                            {
                                                label: 'Añadir',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Editar',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Eliminar',
                                                has_permision: false
                                            }
                                        ] 
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: 'Ventas',
                        subcategories: [
                            {
                                name: 'Caja',
                                modules: [
                                    {
                                        name: 'Ventas',
                                        actions: [
                                            {
                                                label: 'Venta',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Cancelación venta',
                                                has_permision: false,
                                            },
                                            {
                                                label: 'Cotización',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Apartados',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Cotizaciones',
                                        actions: [
                                            {
                                                label: 'Venta cotizacion',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Apartados',
                                        actions: [
                                            {
                                                label: 'Añadir pago',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Cancelar apartado',
                                                has_permision: false,
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Cambios/ Devoluciones',
                                        actions: [
                                            {
                                                label: 'sin definir',
                                                has_permision: false
                                            },
                                        ] 
                                    },
                                    {
                                        name: 'Corte de caja',
                                        actions: [
                                            {
                                                label: 'corte de caja',
                                                has_permision: false
                                            },
                                        ] 
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: 'Inventarios',
                        subcategories: [
                            {
                                name: 'Movimientos',
                                modules: [
                                    {
                                        name: 'Generación de orden',
                                        actions: [
                                            {
                                                label: 'Generar ordenes',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Gestor de ordenes',
                                        actions: [
                                            {
                                                label: 'Completar orden',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Cancelar orden',
                                                has_permision: false
                                            },

                                        ] 
                                    },
                                    {
                                        name: 'Inventario fisico',
                                        actions: [
                                            {
                                                label: 'Listar inventarios',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Traspaso a almacenes',
                                        actions: [
                                            {
                                                label: 'Traspaso: inventario a almacen',
                                                has_permision: false
                                            }
                                        ] 
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: 'Almacenes',
                        subcategories: [
                            {
                                name: 'Productos',
                                modules: [
                                    {
                                        name: 'Admr. de almacenes',
                                        actions: [
                                            {
                                                label: 'listar inventarios',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Traspaso entre almacenes',
                                        actions: [
                                            {
                                                label: 'Traspaso: almacen a almacen',
                                                has_permision: false
                                            }
                                        ] 
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: 'Reportes',
                        subcategories: [
                            {
                                name: 'Productos',
                                modules: [
                                    {
                                        name: 'Corte de caja simplificado',
                                        actions: [
                                            {
                                                label: 'listar ventas',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Impresión del reporte',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Reporte de ventas',
                                        actions: [
                                            {
                                                label: 'Listar ventas',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Impresión del reporte',
                                                has_permision: false
                                            }
                                        ] 
                                    },
                                    {
                                        name: 'Reporte de cambios',
                                        actions: [
                                            {
                                                label: 'Listar cambios/devoluciones',
                                                has_permision: false
                                            },
                                            {
                                                label: 'Impresión del reporte',
                                                has_permision: false
                                            }
                                        ] 
                                    }
                                ]
                            }
                        ]
                    },
                ],
                //paginator
                current_page: 1,
                rows: 10,
                search_query: ''
            };
        },
        computed: {
            ...mapGetters({
                user_roles: 'user_roles/getUserRoles',
                user_rol: 'user_roles/getUserRole',
                total_pages: 'user_roles/getTotalPages',
                total_rows: 'user_roles/getTotalRows'
            })
        },
        methods: {
            //helpers
            clearData(){
                this.open_modal = false
                this.user_rol_name = null
                this.selected_id = null
                this.delete_modal = false
                this.error_user_role_name = null
                this.rol_id = null
            },
            async viewAccess(){
                await this.$store.dispatch('access_module/viewAccess', { id_rol: this.rol_id})
            },
            async selectUserRole(type, id) {
                switch(type){
                    case 'edit':
                        this.selected_id = id
                        await this.$store.dispatch('user_roles/viewUserRole', {id_user_role: this.selected_id})
                        this.user_role_name = this.user_rol.role_name
                        this.open_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                }
            },

            //user rol CRUD
            async createUpdateUserRole(){
                this.error_user_role_name = null
                let complete = true

                if(_.isNull(this.user_role_name) || _.isEmpty(this.user_role_name) ) {
                    this.error_user_role_name = '*Campo obligatorio'
                    complete = false
                    return 
                }

                const payload = {
                    name: this.user_role_name
                }

                if(complete) {
                    if(this.selected_id) {
                        let data = {
                            body: payload,
                            query: {
                                id_user_role: this.selected_id
                            }
                        }
                        await this.$store.dispatch('user_roles/editUserRole', data)
                        await this.navigate()
                        this.clearData()
                        return
                    }
                    else {
                        await this.$store.dispatch('user_roles/insertUserRole', payload)
                        await this.navigate()
                        this.clearData()
                    }
                }
            },
            async deleteUserRole(){
                await this.$store.dispatch('user_roles/deleteUserRole', { role_id: this.selected_id})
                await this.navigate();
                this.clearData();
            },
            async searchRole() {
                await this.navigate();
            },

            //paginator
            async navigate(){
                this.loading = true
                this.empty_search = false

                if(this.current_page > this.total_pages){
                    this.current_page = this.total_pages == 0 ? 1 : this.current_page;
                }
	            
				await this.$store.dispatch('user_roles/listUserRoles', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
	            
                if(!this.user_roles) {
                    this.empty_search = true;
                    return
                }

                else {
                    this.user_roles_data = this.user_roles
                }
                
                this.loading = false;
            },
            prev(){
                this.current_page--;
                this.navigate()
            },
            next(){
                this.current_page++;
                this.navigate()
            }
        },
        async mounted() {
            try {
                //lista de roles
                await this.navigate();

                //opciones de roles
                this.user_role_options = this.user_roles.map( rol => {
                    return {
                        id: rol.id_role,
                        label: rol.role_name
                    }
                })

                this.loading = false
                this.fetch_error = false
            }

            catch(e) {
                console.error(e)
                this.loading = false
                this.fetch_error = true
            }
                
        }
    }
</script>

<style lang="scss" scoped>
    .border-custom{
        border: 1px solid gray;
    }
</style>