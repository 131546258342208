<template>
    <div class="row bg-white mb-5 px-2">
        <div class="col-12">

            <div class="row p-0 m-0 bg-lightgray my-3 p-3" >
                <div class="col-12 col-md-5 col-xxl-3 opensans-bold p-0">
                    <ki-input @input="searchSubcategory" 
                        label="Buscar subcategoria"
                        v-model="search_query"
                        placeholder="Ej: Preparatoria"
                        full>
                    </ki-input>
                </div>
                <div class="col-12 col-md-6 col-xxl-3 opensans-bold p-0 d-flex align-items-end pl-2 pt-3 pt-xxl-0">
                    <button @click="open_modal = true"
                        class="btn btn-white pills py-0"
                        style="height: 35px;"
                        type="button">
                        <img :src="plus_icon"/>
                        Añadir Subcategoria
                    </button>
                </div>
            </div>

        </div>

        <div class="col-12 p-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div class="col-12 opensans-bold text-dark" v-if="fetch_error">
            <p class="m-0">
                Un error ha ocurrido intente más tarde
            </p>
        </div>

        <div class="col-12 mt-2 mb-3" v-if="!loading && !fetch_error">
            <ki-table :data="subcategory_table_data"
                :allow_headers="subcategory_table_headers"
                no_pagination color_body="white" color_headers="white">
                <template v-slot:Editar="data">
                    <button @click="selectSubcategory('edit', data.item.id_sub_category)"
                        class="pills bg-white py-0 px-3"
                        type="button">
                        <img :src="write_icon"/>
                    </button>
                </template>
                <template v-slot:Borrar="data">
                    <button @click="selectSubcategory('delete', data.item.id_sub_category, data.item.id_category)"
                        class="pills bg-white py-0 px-3"
                        type="button">
                        <img :src="delete_icon" />
                    </button>
                </template>
            </ki-table>
        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página: 
                    </label>
                    <ki-input @keyup.enter="navigate"
                        v-model="rows"
                        :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                        class="btn btn-primary text-white pills"
                        type="button"
                        :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                        v-model="current_page"
                        full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                        class="btn btn-primary text-white pills"
                        :disabled="current_page == total_pages"
                        type="button">
                        <b-icon icon="arrow-right"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <!--modal create edit Subcategory---->
        <ki-modal v-if="open_modal">
            <div class="row bg-white position-relative text-dark opensans-bold p-0" style="border-radius: 15px;">
                <button @click="clearData"
                    class="btn position-absolute bg-white p-0" 
                    style="right: -10px; top: -10px; width: 20px; box-shadow: -2px 2px 10px rgba(0,0,0, 0.5)">
                    X
                </button>
                <div class="col-12 opensans-bold py-2" v-if="!selected_subcategory_id">
                    <p class="m-0">
                        Añadir subcategoria
                    </p>
                </div>
                <div class="col-12 opensans-bold py-2" v-if="selected_subcategory_id">
                    <p class="m-0">
                        Editar subcategoria
                    </p>
                </div>
                <div class="col-6 opensans-bold p-0 pl-3">
                    <ki-select label="Category"
                        :options="category_options"
                        v-model="selected_category_id"
                        :hidden_option="'Seleccione...'"
                        full>
                    </ki-select>
                    <span v-if="error_category_id" class="text-danger">
                        {{ error_category_id }}
                    </span>
                </div>
                <div class="col-6  opensans-bold pl-3">
                    <ki-input v-model="subcategory_name" 
                        label="Subcategoria"
                        placeholder="Subcategoria..."
                        type="text"
                        full/>
                    <span v-if="error_subcategory_name" class="text-danger">
                        {{ error_subcategory_name}}
                    </span>
                </div>
                <div class="col-12 pb-3">
                    <ki-input v-model="subcategory_description" 
                        label="Descripción"
                        placeholder="Añadir descripción..."
                        type="text"
                        full/>
                    <span v-if="error_subcategory_description" class="text-danger">
                        {{ error_subcategory_description }}
                    </span>
                </div>
                <div class="col-12 p-2 d-flex justify-content-end">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="createUpdateSubcategory"
                        class="btn btn-primary pills text-white ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>    
            </div>
        </ki-modal>

        <!--modal delete subcategory-->
        <ki-modal v-if="show_delete_modal">
            <div class="row bg-white justify-content-center p-4 position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                    class="position-absolute btn btn-white p-0"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2);"
                    type="button">
                    X
                </button>

                <div class="col-12 text-black text-center opensans-bold">
                    <p>
                        ¿Está seguro de borrar esta subcategoria?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click="deleteSubcategory"
                        class="btn btn-danger pills text-white"
                        type="button">
                        Borrar
                    </button>
                    <button @click="clearData"
                        class="btn btn-white pills ml-4"
                        type="button">
                        Cancelar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //icons
    import plus_icon from 'ASSETS/icons/plus-blue-icon.png'
    import write_icon from 'ASSETS/icons/icon_write.png'
    import delete_icon from 'ASSETS/icons/icon_delete_red.png'

    //helpers
    import { mapGetters } from 'vuex'

    export default {
        name: 'subcategories',
        data() {
            return {
                plus_icon,
                write_icon,
                delete_icon,
                subcategory_name: null,
                subcategory_description: null,
                subcategory_id: null,
                subcategory_table_headers: [
	                {key: 'category_name', label: 'Categoria'},
                    { key: 'sub_category_name', label:'Subcategoria'},
                    { key: 'sub_category_description', label: 'Descripción'},
                    { key: 'Editar', label: 'Editar' , width: 30},
                    { key: 'Borrar', label: 'Borrar', width: 30}
                ],
                category_options: [],
                subcategory_table_data: [],
                error_subcategory_name: null,
                error_subcategory_description: null,
                error_category_id: null,
                open_modal: false,
                show_delete_modal: false,
                selected_category_id: 'Seleccione...',
                selected_subcategory_id: null,
                loading: true,
                fetch_error: false,
                //paginator
                current_page: 1,
                rows: 10,
                search_query: ''
            };
        },
        computed: {
            ...mapGetters({
                subcategories: 'subcategory/getSubcategories',
                subcategory: 'subcategory/getSubcategory',
                categories: 'category/getCategories',
                total_pages: 'subcategory/getTotalPages',
                total_rows: 'subcategory/getTotalRows'
            })
        },
        methods: {
            //helpers
            clearData(){
                this.selected_category_id = 'Seleccione...'
                this.selected_subcategory_id = null
                this.open_modal = false
                this.subcategory_name = null
                this.subcategory_description = null
                this.show_delete_modal = false

                for(let error in this.errors){
                    this.errors[error] = null
                }
            },
            async selectSubcategory(type, id_subcategory, id_category = null) {
                switch(type) {
                    case 'edit':
                        this.selected_subcategory_id = id_subcategory
                        await this.$store.dispatch('subcategory/viewSubcategory', {subcategory_id: this.selected_subcategory_id})
                        this.selected_category_id = new String(this.subcategory.id_category)
                        this.subcategory_description = this.subcategory.sub_category_description
                        this.subcategory_name = this.subcategory.sub_category_name
                        this.open_modal = true
                        break;
                    case 'delete':
                        this.selected_category_id = id_category
                        this.selected_subcategory_id = id_subcategory
                        this.show_delete_modal = true
                        break;
                }
            },

            //CRUD subcategories
            async createUpdateSubcategory(){
                
                for(let error in this.errors) {
                    this.errors[error] = null;
                }
                
                let is_complete = true

                if(_.isNull(this.selected_category_id) || _.isEmpty(this.selected_category_id) || this.selected_category_id == 'Seleccione...') {
                    this.error_category_id = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.subcategory_name) || _.isEmpty(this.subcategory_name)) {
                    this.error_subcategory_name = '*Campo obligatorio'
                    is_complete = false
                }

                if(_.isNull(this.subcategory_description) || _.isEmpty(this.subcategory_description)){
                    this.error_subcategory_description = '*Campo obligatorio'
                    is_complete = false
                    return
                }

                const payload = {
                    id_category: this.selected_category_id,
                    name: this.subcategory_name,
                    description: this.subcategory_description
                }

                if (is_complete) {
                    if(this.selected_subcategory_id) {

                        let data = {
                            body: payload,
                            query: {
                                id_subcategory: this.selected_subcategory_id
                            }
                        }

                        await this.$store.dispatch('subcategory/editSubcategory', data)
                        await this.navigate()
                        this.clearData()
                        return
                    }
                    else {
                        await this.$store.dispatch('subcategory/insertSubcategory', payload)
                        await this.navigate()
                        this.clearData()
                    }
                }
            },
            async deleteSubcategory(){
                await this.$store.dispatch('subcategory/deleteSubcategory', { category_id: this.selected_category_id, subcategory_id: this.selected_subcategory_id})
                this.show_delete_modal = false
                this.selected_category_id = null
                this.selected_subcategory_id = null
                await this.navigate()
            },
            async searchSubcategory(){
                await this.navigate();
            },
            //paginator
            async navigate(){
                this.empty_search = false
                this.loading = true

                if(this.current_page > this.total_pages){
                    this.current_page = this.total_pages == 0 ? 1 : this.current_page;
                }

	            await this.$store.dispatch('subcategory/listSubcategories', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
                
                    
                if(!this.subcategories) {
                    this.empty_search = true;
                    return
                }

                else {
                    this.subcategory_table_data = this.subcategories
                }

                this.loading = false
                    
            },
            prev(){
                this.current_page--;
                this.navigate()
            },
            next(){
                this.current_page++;
                this.navigate()
            }
        },
        async mounted() {
            try {
                //lista de subcategorias
                await this.navigate()

                //opciones de categorias
                await this.$store.dispatch('category/listCategories', {datatable: false})
                this.category_options = this.categories.map( category => {

                    return { 
                        id: category.id_category, 
                        label: category.category_name 
                        }
                })

                this.fetch_error = false;
            }
            catch(e) {
                console.error(e)
                this.category_options = [{id: 1, label: 'Ha ocurrido un error intente más tarde'}]
                this.loading = false
                this.fetch_error = true
            }
            
        }
    }
</script>