<template>
    <div id="config-user" class="container-fluid mb-4">

        <div class="row bg-white">  

            <div class="col-12 bg-white py-3">
                <div class="row bg-lightgray py-3 opensans-bold">

                    <div class="col-3">
                        <ki-input @input="search"
	                        label="Busqueda:"
                            placeholder="Buscar..."
                            type="text"
                            v-model="search_query"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-4 d-flex align-items-end">
                        <button @click="employee_modal = true"
                            class="btn btn-white pills"
                            type="button">
                            <img :src="icon_add" />
                            Agregar nuevo empleado
                        </button>
                    </div>
                </div>
            </div>
        
            <div class="col-12 bg-white py-3" v-if="loading">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12 bg-white" v-if="fetch_error">
                <p class="m-0">
                    Un error ha ocurrido intentalo más tarde
                </p>
            </div>

            <div class="col-12 p-3  bg-white" v-if="!loading && !fetch_error">
                <ki-table :data="data_table" 
                    :allow_headers="headers_allow" 
                    no_pagination color_headers="white" color_body="white">
                    <template v-slot:Editar="data">
                        <button @click="selectEmployee('edit',data.item.id_employee)" 
                            class="pills bg-white p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="icon_write">
                        </button>
                    </template>
                    <template v-slot:Borrar="data">
                        <button @click="selectEmployee('delete', data.item.id_employee)"
                            class="pills bg-white p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="icon_delete_red" />
                        </button>
                    </template>
                </ki-table>
            </div>

            <!-- Paginator -->
            <div class="col-12 p-0 m-0 mt-2 pb-3">
                <div class="row justify-content-between align-items-center text-dark">
                    <div class="col d-flex">
                        <label class="mr-2">
                            Elementos por página: 
                        </label>
                        <ki-input @keyup.enter="navigate"
                            v-model="rows"
                            :disabled="current_page > 1">
                        </ki-input>
                    </div>

                    <div class="col-1  d-flex justify-content-end p-0">
                        <button @click.stop="prev"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="current_page == 1">
                            <b-icon icon="arrow-left"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1" style="max-width: 80px;">
                        <ki-input @keyup.enter="navigate"
                            v-model="current_page"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-1">
                        <p class="m-0">
                            de {{  total_pages  }}
                        </p>
                    </div>

                    <div class="col-1 p-0" style="max-width: 65px">
                        <button @click.stop="next"
                            class="btn btn-primary text-white pills"
                            :disabled="current_page == total_pages"
                            type="button">
                            <b-icon icon="arrow-right"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                </div>
            </div>
        </div>


        <!--create edit users-->
        <ki-modal v-if="employee_modal">
            <div class="row bg-white text-dark position-relative p-2 opensans-bold" style="border-radius: 10px">
                <button @click="clearData"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                    class="btn btn-white p-0 position-absolute"
                    type="button">
                    X
                </button>

                <div class="col-12" v-if="!selected_id">
                    <p>
                        Añadir nuevo Empleado
                    </p>
                </div>
                <div class="col-12" v-if="selected_id">
                    <p>
                        Editar empleado existente
                    </p>
                </div>

                <div class="col-6 col-lg-4">
                    <ki-input 
                        v-model="name"
                        label="Nombre:"
                        placeholder="Nombre del empleado"
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.name">
                        {{ errors_employee.name }}
                    </span>
                </div>

                <div class="col-6 col-lg-4">
                    <ki-input 
                        v-model="first_last_name"
                        label="Apellido Paterno:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.first_last_name">
                        {{ errors_employee.first_last_name }}
                    </span>
                </div>

                <div class="col-6 col-lg-4 my-2 my-lg-0">
                    <ki-input 
                        v-model="second_last_name"
                        label="Apellido Materno:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.second_last_name">
                        {{ errors_employee.second_last_name }}
                    </span>
                </div>

                
                <div class="col-6 col-lg-4 my-2 my-lg-0">
                    <ki-input 
                        v-model="rfc"
                        label="RFC:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.rfc">
                        {{ errors_employee.rfc }}
                    </span>
                </div>

                <div class="col-6 col-lg-4">
                    <ki-input 
                        v-model="date_of_birth"
                        label="Fecha de nacimiento:"
                        type="date"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.date_of_birth">
                        {{ errors_employee.date_of_birth }}
                    </span>
                </div>

                <div class="col-6 col-lg-4">
                    <ki-input 
                        v-model="email"
                        label="Email:"
                        placeholder=""
                        type="email"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.email">
                        {{ errors_employee.email }}
                    </span>
                </div>


                <div class="col-6 col-lg-4 my-2 my-lg-0">
                    <ki-input 
                        v-model="phone"
                        label="Telefono:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.phone">
                        {{ errors_employee.phone }}
                    </span>
                </div>

                <div class="col-6 col-lg-4 my-2 my-lg-0">
                    <ki-input 
                        v-model="address"
                        label="Dirección:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.address">
                        {{ errors_employee.address }}
                    </span>
                </div>

                <div class="col-6 col-lg-4">
                    <ki-input 
                        v-model="zip_code"
                        label="Código postal:"
                        placeholder=""
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_employee.zip_code">
                        {{ errors_employee.zip_code }}
                    </span>
                </div>

                <div class="col-6 col-lg-12 p-3 d-flex justify-content-end">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    
                    <button @click="createUpdateEmployee"
                        class="btn btn-primary pills text-white ml-3"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!---Modal delete employee-->
        <ki-modal v-if="delete_modal">
            <div class="row bg-white justify-content-center p-4 position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                    class="position-absolute btn btn-white p-0"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2);"
                    type="button">
                    X
                </button>

                <div class="col-12 text-black text-center opensans-bold">
                    <p>
                        ¿Está seguro de borrar este empleado?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click="deleteEmployee"
                        class="btn btn-danger pills text-white"
                        type="button">
                        Borrar
                    </button>
                    
                    <button @click="clearData"
                        class="btn btn-white pills ml-4"
                        type="button">
                        Cancelar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>
    //Components
    import Titles from 'COMPONENTS/Titles.vue'
    import TitleTabs from 'COMPONENTS/TitleTabs.vue'

    //Icons
    import icon_write from 'ASSETS/icons/icon_write.png';
    import icon_delete_red from 'ASSETS/icons/icon_delete_red.png'; 
    import icon_add from 'ASSETS/icons/plus-blue-icon.png'

    //helpers
    import { mapGetters  } from 'vuex';

export default {
    name: 'ki-config-employees',
    components: {
        'titles': Titles,
        'title-tabs': TitleTabs,
    },
    data() {
        return {
            icon_write,
            icon_delete_red,
            icon_add,
            loading: true,
            fetch_error: false,
            employee_modal: false,
            delete_modal: false,
            selected_id: false,
            name: null,
            first_last_name: null,
            second_last_name: null,
            rfc: null,
            date_of_birth: null,
            email: null,
            phone: null,
            address: null,
            zip_code: null,
            headers_allow: [
                { key: 'first_name', label: "Nombre"}, 
                { key: 'Editar', label: "Editar", width: '60' }, 
                { key: 'Borrar', label: "Borrar", width: '60' }, 
            ],
            data_table: [],
            errors_employee: {
                name: null,
                first_last_name: null,
                second_last_name: null,
                rfc: null,
                date_of_birth: null,
                email: null,
                phone: null,
                address: null,
                zip_code: null,
            },
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            employees: 'employees/getEmployees',
            employee: 'employees/getEmployee',
            total_pages: 'employees/getTotalPages',
            total_rows: 'employees/getTotalRows'
        })
    },
    methods: {
        //helpers
        formatDate(date){
            const raw_date = new Date(date).toLocaleDateString('en-US')

            let date_array = raw_date.split("/")
            date_array = date_array.reverse()

            if(date_array[2] < 10) {
                date_array[2] = `0${date_array[2]}`
            }
            
            if(date_array[1] < 10){
                date_array[1] = `0${date_array[1]}`
            }

            const date_string = `${date_array[0]}-${date_array[2]}-${date_array[1]}`

            return date_string
        },
        clearData(){
            this.selected_id  = null
            this.employee_modal = false
            this.delete_modal = false
            this.name  = null
            this.first_last_name = null
            this.second_last_name = null
            this.rfc = null
            this.date_of_birth = null
            this.email = null
            this.phone = null
            this.address = null
            this.zip_code = null
            
            for(let error in this.errors_employee) {
                this.errors_employee[error] = null
            }

        },
        async selectEmployee(type, id){
            switch(type){
                case 'edit':
                    this.selected_id = id  
                    await this.$store.dispatch('employees/viewEmployee', { employee_id: id})
                    this.name = this.employee.first_name
                    this.first_last_name = this.employee.last_name
                    this.second_last_name = this.employee.second_surname
                    this.rfc = this.employee.rfc
                    this.date_of_birth = this.formatDate(this.employee.birthday)
                    this.email = this.employee.email
                    this.phone  = this.employee.phone
                    this.address  = this.employee.address
                    this.zip_code  = this.employee.zip_code
                    this.employee_modal = true  
                    console.log(this.date_of_birth)
                    break;
                case 'delete':
                    this.selected_id  = id
                    this.delete_modal  = true
                    break;
            }
        },

        //employee CRUD
        async createUpdateEmployee() {

            for(let error in this.errors_employee){
                this.errors_employee[error] = null
            }

            let complete = true

            
            if(_.isNull(this.name) || _.isEmpty(this.name)) {
                this.errors_employee.name = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.first_last_name) || _.isEmpty(this.first_last_name)) {
                this.errors_employee.first_last_name = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.second_last_name) || _.isEmpty(this.second_last_name)) {
                this.errors_employee.second_last_name = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.rfc) || _.isEmpty(this.rfc)) {
                this.errors_employee.rfc = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.date_of_birth) || _.isEmpty(this.date_of_birth)) {
                this.errors_employee.date_of_birth = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.email) || _.isEmpty(this.email)) {
                this.errors_employee.email = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.phone) || _.isEmpty(this.phone)) {
                this.errors_employee.phone = '*Campo obligatorio'
                complete = false
            }
            if(_.isNull(this.address) || _.isEmpty(this.address)) {
                this.errors_employee.address   = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.zip_code)) {
                this.errors_employee.zip_code= '*Campo obligatorio'
                complete = false
            }

            if(complete) {
                const payload = { 
                    first_name: this.name,
                    last_name: this.first_last_name,
                    second_surname: this.second_last_name,
                    rfc: this.rfc,
                    birthday: this.date_of_birth,
                    email: this.email,
                    phone: this.phone,
                    address: this.address,
                    zip_code: this.zip_code,
                }

                if(this.selected_id) {

                    let data = {
                        body: payload,
                        query: {
                            id_employee: this.selected_id
                        }
                    }
                    await this.$store.dispatch('employees/editEmployeeRole', data)  
                    await this.navigate();
                    this.clearData()
                }

                else {
                    await this.$store.dispatch('employees/insertEmployee', payload )   
                    await this.navigate();
                    this.clearData();
                    this.employe_modal = false
                }
            }
            
        },
        async deleteEmployee() {

            await this.$store.dispatch('employees/deleteEmployee', { id_employee: this.selected_id} )
            await this.navigate()
            this.delete_modal  = false
            this.selected_id = false
        },
		async search() {
			await this.navigate();
		},
        //paginator
        async navigate(){
            this.loading = true
            this.empty_search = false

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }

	        await this.$store.dispatch('employees/listEmployees', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
            
                    
            if(!this.employees) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.employees
            }
            
            this.loading = false;
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async mounted() {
        try {
            await this.navigate()
            this.fetch_error = false
        }
        catch (e) {
            this.loading = false
            this.fetch_error = true
        }
        
    }
};
</script>