<template>
    <div id="config-user" class="container-fluid mb-3">
        <div class="row bg-white">
            
            <div class="col-12 my-3">
                <div class="row bg-lightgray py-3">
                    <div class="col-3 opensans-bold">
                        <ki-input  @input="search"
	                        label="Busqueda:"
                            _id="search"
                            placeholder="Buscar..."
                            type="text"
                            v-model="search_query"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-4 d-flex align-items-end">
                        <button @click="open_modal = true"
                            class="btn btn-white pills"
                            type="button">
                            <img :src="icon_add" />
                            Agregar nuevo usuario
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-12 py-3" v-if="loading">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12" v-if="fetch_error">
                <p class="m-0">
                    Un error ha ocurrido intentalo más tarde
                </p>
            </div>

            <div class="col-12 px-3 mb-3 " v-if="!loading && !fetch_error">
                <ki-table :data="data_table" 
                    :allow_headers="headers_allow" 
                    no_pagination color_headers="white" color_body="white">
                    <template v-slot:password="data">

                        <button @click="selectUser('pwd_update', data.item.id_user)" 
                            class="pills bg-white p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="icon_write" />
                        </button>
                    </template>

                    <template v-slot:Editar="data">
                        <button @click="selectUser('edit', data.item.id_user)" 
                            class="pills bg-white p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="icon_write">
                        </button>
                    </template>

                    <template v-slot:Borrar="data">
                        <button @click="selectUser('delete', data.item.id_user)"
                            class="pills bg-white p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="icon_delete_red" />
                        </button>
                    </template>

                </ki-table>
            </div>

            <div class="col-12 p-0 m-0 mt-2">
                <div class="row justify-content-between align-items-center text-dark">
                    <div class="col d-flex">
                        <label class="mr-2">
                            Elementos por página: 
                        </label>
                        <ki-input @keyup.enter="navigate"
                            v-model="rows"
                            :disabled="current_page > 1">
                        </ki-input>
                    </div>

                    <div class="col-1  d-flex justify-content-end p-0">
                        <button @click.stop="prev"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="current_page == 1">
                            <b-icon icon="arrow-left"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1" style="max-width: 80px;">
                        <ki-input @keyup.enter="navigate"
                            v-model="current_page"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-1">
                        <p class="m-0">
                            de {{  total_pages  }}
                        </p>
                    </div>

                    <div class="col-1 p-0" style="max-width: 65px">
                        <button @click.stop="next"
                            class="btn btn-primary text-white pills"
                            :disabled="current_page == total_pages"
                            type="button">
                            <b-icon icon="arrow-right"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                </div>
            </div>

        </div>


        <!--create edit users-->
        <ki-modal v-if="open_modal">
            <div class="row bg-white text-dark position-relative" style="border-radius: 10px">
                <button @click="clearData"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                    class="btn btn-white p-0 position-absolute"
                    type="button">
                    X
                </button>

                <div class="col-12" v-if="!selected_id">
                    <p>
                        Añadir nuevo usuario
                    </p>
                </div>

                <div class="col-12" v-if="selected_id">
                    <p>
                        Editar usuario existente
                    </p>
                </div>

                <div class="col-4">
                    <ki-select 
                        v-model="employee_id"
                        label="Empleado:"
                        :hidden_option="'Seleccione...'"
                        :options="employees_options"
                        :disabled="selected_id"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="errors.employee_id">
                        {{ errors.employee_id }}
                    </span>
                </div>

                <div class="col-4">
                    <ki-select 
                        v-model="user_role_id"
                        label="Rol de usuario:"
                        :options="user_roles_options"
                        :disabled="selected_id"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="errors.user_role_id">
                        {{ errors.user_role_id }}
                    </span>
                </div>

                <div class="col-4">
                    <ki-input 
                        _id="username"
                        v-model="username"
                        label="Nombre de usuario:"
                        placeholder="ej: jesus.test"
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.username">
                        {{ errors.username }}
                    </span>
                </div>

                <div class="col-4" v-if="!selected_id">
                    <ki-input 
                        _id="pwsd"
                        v-model="password"
                        label="Contraseña:"
                        type="password"
                        :disabled="selected_id"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.password">
                        {{ errors.password }}
                    </span>
                </div>

                <div class="col-4" v-if="!selected_id">
                    <ki-input @input="checkEqualPassword(password, confirmation_password)"
                        _id="pwsd_con"
                        v-model="confirmation_password"
                        label="Confirmar contraseña:"
                        type="password"
                        :disabled="selected_id"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.password_confirmation">
                        {{ errors.password_confirmation }}
                    </span>
                    <span style="color: #37CB5F" v-if="equal">
                        Las contraseñas coinciden
                    </span>
                </div>

                <div class="col-12 p-3 d-flex justify-content-end">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="createEditUser"
                        class="btn btn-primary pills text-white ml-3"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!--delete modal-->
        <ki-modal v-if="delete_modal">
            <div class="row bg-white text-dark position-relative p-3" 
                style="border-radius: 10px;">
                <button @click.stop="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.5)"
                    type="button">
                    X
                </button>

                <div class="col-12 text-center opensans-bold">
                    <p>
                        ¿Esta seguro de eliminar este usuario?
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    
                    <button @click.stop="deleteUser"
                        class="btn btn-danger pills text-white ml-2"
                        type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!--new password modal-->
        <ki-modal v-if="edit_password_modal">
            <div class="row bg-white text-dark position-relative" style="border-radius: 10px">
                <button @click.stop="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2)"
                    type="button">
                    X
                </button>

                <div class="col-12">
                    <p>
                        Editar contraseña
                    </p>
                </div>

                <div class="col-12">
                    <ki-input label="Nueva Contraseña:"
                        _id="new_pswd"
                        v-model="new_password"
                        type="password"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_new_password">
                        {{ errors_new_password.new_password }}
                    </span>
                </div>

                <div class="col-12">
                    <ki-input @input="checkEqualPassword(new_password, confirmation_new_password)"
                        _id="com_new_pswd"
                        label="Confirmar contraseña:"
                        v-model="confirmation_new_password"
                        type="password"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors_new_password">
                        {{ errors_new_password.confirmation_new_password  }}
                    </span>
                    <span style="color: #37CB5F" v-if="equal">
                        Las contraseñas coinciden
                    </span>
                </div>

                <div class="col-12 p-4 d-flex justify-content-end">
                    <button @click.stop="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="editUserPassword"
                        class="btn btn-primary pills text-white ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>

    //Icons
    import icon_write from 'ASSETS/icons/icon_write.png';
    import icon_delete_red from 'ASSETS/icons/icon_delete_red.png'; 
    import icon_add from 'ASSETS/icons/plus-blue-icon.png'

    //helpers
    import { mapGetters  } from 'vuex';

export default {
    name: 'ki-config-user',
    data() {
        return {
            headers_allow: [
                { key: 'username', label: "Nombre de usuario"}, 
                { key: 'role_name', label: 'Rol', widht: '50'},
                { key: 'password', label: "Password", width: '50' }, 
                { key: 'Editar', label: "Editar", width: '60' }, 
                { key: 'Borrar', label: "Borrar", width: '60' }, 
            ],
            data_table: [],
            icon_write,
            icon_delete_red,
            icon_add,
            open_modal: false,
            delete_modal: false,
            loading: true,
            fetch_error: false,
            selected_id: null,
            employee_id: null,
            user_role_id: null,
            employees_options: [],
            user_roles_options: [],
            username: null,
            password: null,
            confirmation_password: null,
            errors: {
                employee_id: null,
                user_role_id: null,
                username: null,
                password: null,
                password_confirmation: null
            },
            //new password
            edit_password_modal: false,
            new_password: null,
            confirmation_new_password: null,
            equal: false,
            errors_new_password: {
                new_password: null,
                confirmation_new_password: null
            },
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            users: 'users/getUsers',
            user: 'users/getUser',
            user_roles: 'user_roles/getUserRoles',
            employees: 'employees/getEmployees',
            total_pages: 'users/getTotalPages',
            total_rows: 'users/getTotalRows'
        })
    },
    methods: {
        //helpers
        clearData(){
            this.open_modal = false
            this.delete_modal = false
            this.edit_password_modal = false
            this.equal = false
            this.selected_id = null

            this.username = null
            this.employee_id = null
            this.user_role_id = null
            this.password = null
            this.confirmation_password = null
            
            this.new_password = null
            this.confirmation_new_password = null

            for(let error in this.errors) {
                this.errors[error] = null
            }

            for(let error in this.errors_new_password) {
                this.errors_new_password[error] = null
            }

        },
        checkEqualPassword(password, confirmation_password) {
            if(password !== confirmation_password) {
                this.errors_new_password.confirmation_new_password = '*Las contraseñas no coinciden'
                this.errors.password_confirmation = '*Las contraseñas no coinciden'
                this.equal = false
            } 
            else {
                this.errors_new_password.confirmation_new_password = null
                this.errors.password_confirmation = null
                this.equal = true
            }
        },
        async selectUser(type, id){
            switch(type){
                case 'edit':
                    this.selected_id = id
                    await this.$store.dispatch('users/viewUser', { id_user: this.selected_id})
                    this.user_role_id = this.user.id_role.toString()
                    this.employee_id = this.user.id_employee.toString()
                    this.username = this.user.username
                    this.open_modal = true
                    break;
                case 'pwd_update':
                    this.selected_id = id
                    this.edit_password_modal = true
                    break;
                case 'delete':
                    this.selected_id = id
                    this.delete_modal = true
                    break;
            }
        },

        //users CRUD
        async createEditUser() {
            for(let error in this.errors_user) {
                this.errors[error] = null
            }

            let complete = true
            //validaciones
            if(_.isNull(this.employee_id) || _.isEmpty(this.employee_id)) {
                console.log('EMPLOYEE_ID:',this.employee_id)
                this.errors.employee_id = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.user_role_id) || _.isEmpty(this.user_role_id)) {
                this.errors.user_role_id = '*Campo obligatorio'
                complete = false
            }

            if(_.isNull(this.username) || _.isEmpty(this.username)) {
                this.errors.username = '*Campo obligatorio'
                complete = false
            }

            if((_.isNull(this.password) || _.isEmpty(this.password)) && !this.selected_id ) {
                this.errors.password = '*Campo obligatorio'
                complete = false
            }

            if((_.isNull(this.confirmation_password) || _.isEmpty(this.confirmation_password)) && !this.selected_id) {
                this.errors.password_confirmation = '*Campo obligatorio'
                complete = false
            }

            if ((this.password !== this.confirmation_password) && !this.selected_id) {
                this.errors.password_confirmation = '*Las contraseñas deben ser iguales'
                complete = false
                return
            }

            if(complete) {
                
                const payload = {
                    "username": this.username,
                    "id_employee": this.employee_id,
                    "id_user_role": this.user_role_id,
                    "password": this.password,
                }

                if(this.selected_id) {
                    const payload = {
                        id_user: this.selected_id,
                        username: this.username,
                        id_user_role: this.user_role_id
                    }

                    await this.$store.dispatch('users/editUser', payload)
                    await this.navigate()
                    this.clearData()
                    return
                    
                } 

                else {
                    await this.$store.dispatch('users/insertUser', payload)
                    await this.navigate()
                    this.clearData()
                }
            }
        },
        async editUserPassword() {

            for(let error in this.errors_new_password) {
                this.errors_new_password[error] = null
            }

            let is_complete = true;

            if(_.isNull(this.new_password) || _.isEmpty(this.new_password)) {
                is_complete = false;
                this.errors_new_password.new_password = '*Campo obligatorio'
            }
            
            if(_.isNull(this.confirmation_new_password) || _.isEmpty(this.confirmation_new_password)) {
                is_complete = false
                this.errors_new_password.confirmation_new_password = '*Campo obligatorio'
                return
            }
            else if(this.new_password !== this.confirmation_new_password) {
                is_complete = false
                this.errors_new_password.confirmation_new_password = '*Las contraseñas deben ser iguales'
                return
            }

            const payload = {
                id_user: this.selected_id,
                password : this.new_password
            }

            if(is_complete) {
                await this.$store.dispatch('users/changeUserPassword', payload)
                this.clearData()
                return
            }
        },
        async deleteUser() {
            await this.$store.dispatch('users/deleteUser', { id_user: this.selected_id})
            await this.navigate()
            this.clearData()
        },
	    async search() {
			await this.navigate();
	    },

        //paginator
        async navigate(){
            this.empty_search = false
            this.loading = true
            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }
	        
	        await this.$store.dispatch('users/listUsers', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
            
            if(!this.users) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.users
            }
            
            this.loading = false
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async mounted() {
        try {
            //role options
            await this.$store.dispatch('user_roles/listUserRoles', {datatable: false})
            this.user_roles_options = this.user_roles.map( user_role => {
                return { id: user_role.id_role, label: user_role.role_name}
            })
            
            //employee options
            await this.$store.dispatch('employees/listEmployees', { datable: false })
            this.employees_options = this.employees.map( employee => {
                return { 
                    id: employee.id_employee, 
                    label: `${employee.first_name} ${employee.last_name} ${employee.second_surname}`
                }
            })

            //users list
            await this.navigate();
            this.fetch_error = false
        }

        catch (e) {
            console.error(e)
            this.loading = false
            this.fetch_error = true
        }
        
    }
};
</script>