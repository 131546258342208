<template>
    <div class="container-fluid p-0 mb-3 bg-white text-dark">
        <div class="row px-3">

            <div class="col-12 bg-lightgray p-2 my-3">
                <div class="row align-items-end p-2">

                    <div class="col-6 col-xl-4 opensans-bold p-0">
                        <ki-input @input="searchMovement(search_query)"
                            label="Buscar:"
                            v-model="search_query"
                            placeholder="Ej:Traspaso de almacén"
                            full/>
                    </div>

                    <div class="col-6 col-xl-4">
                        <button @click="open_modal = true"
                            class="btn btn-white pills"
                            type="button">
                            <img :src="add_icon" />
                            Añadir movimiento
                        </button>
                    </div>

                </div>
            </div>

            <div class="col-12 py-3" v-if="loading">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12" v-if="fetch_error">
                <p>
                    Un error ha ocurrido intente más tarde
                </p>
            </div>

            <div class="col-12 py-3 px-0" v-if="!loading && !fetch_error" >
                <ki-table :data="movements_table_data"
                    :allow_headers="movements_table_headers"
                     no_pagination color_body="white" color_headers="white" 
                     full>

                    <template v-slot:Editar="data">
                        <button @click="selectMovement('edit', data.item.id_movement)"
                            class="btn btn-white pills p-0"
                            style="width: 50px;"
                            type="button">
                            <img :src="write_icon"/>
                        </button>
                    </template>

                    <template v-slot:Borrar="data">
                        <button @click="selectMovement('delete', data.item.id_movement)"
                            class="btn btn-white pills p-0"
                            style="width: 50px"
                            type="button">
                            <img :src="delete_icon"/>
                        </button>
                    </template>

                </ki-table>
            </div>

        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2 pb-3 px-3">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página: 
                    </label>
                    <ki-input @keyup.enter="navigate"
                        v-model="rows"
                        :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                        class="btn btn-primary text-white pills"
                        type="button"
                        :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                        v-model="current_page"
                        full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                        class="btn btn-primary text-white pills"
                        :disabled="current_page == total_pages"
                        type="button">
                        <b-icon icon="arrow-right"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <ki-modal v-if="open_modal">
            <div class="row bg-white position-relative text-black p-2" style="border-radius: 15px">
                <button @click="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2)"
                    type="button">
                    X
                </button>
                <div class="col-12">
                    <p class="m-0" v-if="!selected_id">
                        Añadir Movimiento
                    </p>
                    <p class="m-0" v-if="selected_id" >
                        Editar movimiento
                    </p>
                </div>

                <div class="col-12">
                    <ki-input v-model="movement_name"
                        label="Nombre del movimiento:"
                        type="text"
                        placeholder="Ej. Asignación de producto a sucursal"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.name">
                        {{ errors.name }}
                    </span>
                </div>

                <div class="col-12 pt-2">
                    <ki-input v-model="movement_prefix"
                        label="Prefijo:"
                        placeholder="Ej: APS"
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="errors.prefix">
                        {{ errors.prefix}}
                    </span>
                </div>

                <div class="col-12 py-2 d-flex justify-content-end">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="createUpdateMovement"
                        class="btn btn-primary pills text-white ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="delete_modal">
            <div class="row bg-white text-dark p-3 position-relative" style="border-radius: 15px;">
                <button @click.stop="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.5)"
                    type="button">
                    X
                </button>

                <div class="col-12 text-center opensans-bold">
                    <p>
                        ¿Esta seguro de borrar este movimiento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="deleteMovement"
                        class="btn btn-danger text-white pills ml-3"
                        type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </ki-modal>

    </div>
</template>

<script>
    //icons
    import add_icon from 'ASSETS/icons/plus-blue-icon.png'
    import write_icon from 'ASSETS/icons/icon_write.png'
    import delete_icon from 'ASSETS/icons/icon_delete_red.png'

    //helpers
    import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                add_icon,
                write_icon,
                delete_icon,
                open_modal: false,
                delete_modal: false,
                movement_prefix: null,
                movement_name: null,
                selected_id: null,
                loading: true,
                fetch_error: false,
                search_query: null,
                movements_table_headers: [
                    {key:'movement_name', label: 'Movimiento'},
                    {key: 'movement_prefix', label: 'Prefijo'},
                    {key: 'Editar', label: 'Editar', width: '30'},
                    {key: 'Borrar', label: 'Borrar', width: '30'}
                ],
                movements_table_data:[
                    { id: 1, movement: 'Traspaso de almacén', prefix: 'TDA'},
                    { id: 2, movement: 'Venta de mostrador', prefix: 'VDM'},
                    { id: 3, movement: 'Asignacion de productos', prefix: 'ADP'},
                    { id: 4, movement: 'Devolución de mercancia', prefix: 'DEM'},
                    { id: 5, movement: 'Cambio de mercancia', prefix: 'CDM'}
                ],
                errors: {
                    name: null,
                    prefix: null
                },
                //paginator
                current_page: 1,
                rows: 10,
                search_query: null
            };
        },
        computed: {
            ...mapGetters({
                movements: 'movements/getMovements',
                movement: 'movements/getMovement',
                total_pages: 'movements/getTotalPages',
                total_rows: 'movements/getTotalRows'
            })
        },
        methods: {
            //helpers
            clearData(){
                this.movement_name = null
                this.movement_prefix = null
                this.selected_id = null
                this.open_modal = false
                this.delete_modal = false

                for(let error in this.errors){
                    this.errors[error] = null
                }
            },
            async selectMovement(type, id){
                switch(type){
                    case 'edit':
                        this.selected_id = id
                        await this.$store.dispatch('movements/viewMovement', { id: this.selected_id})
                        this.movement_name = this.movement.name
                        this.movement_prefix = this.movement.prefix
                        this.open_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                }
            },
            async createUpdateMovement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.movement_name) || _.isEmpty(this.movement_name)) {
                    this.errors.name = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.movement_prefix) || _.isEmpty(this.movement_prefix)) {
                    this.errors.prefix = '*Campo obligatorio'
                    complete = false
                    return
                }

                if(complete) {

                    const payload = {
                        name: this.movement_name,
                        prefix: this.movement_prefix
                    }

                    if(this.selected_id) {
                        let data = {
                            body: payload,
                            query: {
                                id_movement: this.selected_id
                            }
                        }

                        await this.$store.dispatch('movements/editMovement', data)
                        await this.navigate()
                        this.clearData()
                        return
                    }

                    else {
                        await this.$store.dispatch('movements/insertMovement', payload)
                        await this.navigate()
                        this.clearData()
                    }
                }
            },
            async deleteMovement() {
                await this.$store.dispatch('movements/deleteMovement', { id: this.selected_id})
                await this.navigate()
                this.clearData()
            },
            searchMovement(data) {
                if(!data) this.movements_table_data = this.movements

                this.movements_table_data = this.movements_table_data.filter( movement => movement.name.includes(data))
               
            },

            //paginator
            async navigate(){
                this.empty_search = false
                this.loading = true

                if(this.current_page > this.total_pages){
                    this.current_page = this.total_pages == 0 ? 1 : this.current_page;
                }

                if(this.search_query) {
                    await this.$store.dispatch('movements/listMovements', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
                }

                else {
                    await this.$store.dispatch('movements/listMovements', { datatable: true, page: this.current_page, rows: this.rows, search: null})
                }
                        
                if(!this.movements) {
                    this.empty_search = true;
                    return
                }

                else {
                    this.movements_table_data = this.movements
                }

                this.loading = false
                        
            },
            prev(){
                this.current_page--;
                this.navigate()
            },
            next(){
                this.current_page++;
                this.navigate()
            }
        },
        async mounted() {
            try {
                //list of movements
                this.navigate()
                console.log(this.movements)

                this.fetch_error = false
            }

            catch(e) {
                console.log(e)
                
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>