<template>
    <div id="Catalogues" class="container-fluid px-3">
        <titles title="Catálogos" :subtitle="tab_active" :othertitle="titletab_active"></titles>
        <tabs :array_tabs="tabs_array" @changeTab="controlTabs($event)"></tabs>
        <title-tabs :array_titletabs="titletabs_array" @changeTitle="controlTitles($event)"></title-tabs>

        <div  v-if="tab_active == 'Ventas'">
            <discount v-if="titletab_active === 'Descuentos'" />
            <!--<seasons v-if="titletab_active === 'Temporadas'"-->
            <products v-if="titletab_active === 'Productos'" /> 
            <transaction-types v-if="titletab_active === 'Formas de pago'"/>
            <clients v-if="titletab_active === 'Clientes'" />
        </div>

        <div v-if="tab_active == 'Inventarios'">
            <categories v-if="titletab_active === 'Categorias'" />
            <subcategories v-if="titletab_active === 'Subcategorias'"/>
            <providers v-if="titletab_active === 'Proveedores'" />
        </div>

        <div v-if="tab_active == 'Sucursales'">
            <branches v-if="titletab_active === 'Sucursales'" />
            <warehouses v-if="titletab_active === 'Almacenes'" />
            <losses v-if="titletab_active === 'Motivos de merma'" />
        </div>

        <div v-if="tab_active == 'Usuarios'">
            <users v-if="titletab_active === 'Usuarios'" />
            <employees v-if="titletab_active === 'Empleados'" />
            <users-roles v-if="titletab_active === 'Roles de usuario'" />
	        <empresas v-if="titletab_active === 'Empresas'"></empresas>
        </div>
    </div>
</template>

<script>

    //Components
    import Titles from '../components/Titles.vue'
    import Tabs from '../components/Tabs.vue'
    import TitleTabs from '../components/TitleTabs.vue'

    //icons
    import icon_cash from 'ASSETS/icons/icon_cash.png'
    import icon_inventory from 'ASSETS/icons/icon_inventory.png'
    import icon_reports from 'ASSETS/icons/icon_reports.png'
    import icon_warehouse from 'ASSETS/icons/icon_warehouse.png'

    //Sales
    import Discount from 'VIEWS/Catalogues/Sales/Discount.vue'
    import Products from 'VIEWS/Catalogues/Sales/Products.vue'
    //import Seasons from 'VIEWS/Catalogues/Sales/Seasons.vue'
    import TransactionTypes from 'VIEWS/Catalogues/Sales/TransactionTypes.vue'
    import Clients from 'VIEWS/Catalogues/Sales/Clients.vue'

    //Inventory
    import Categories from 'VIEWS/Catalogues/Inventory/Categories.vue'
    import Providers from 'VIEWS/Catalogues/Inventory/Providers.vue'
    import Subcategory from 'VIEWS/Catalogues/Inventory/Subcategory.vue'
    import Movements from 'VIEWS/Catalogues/Inventory/Movements.vue'
    //import GeneralInventory from 'VIEWS/Catalogues/Inventory/GeneralInventory.vue'

    //Warehouses
    import Branches from 'VIEWS/Catalogues/Warehouses/Branch.vue'
    import Warehouses from 'VIEWS/Catalogues/Warehouses/Warehouses.vue'
    import Losses from 'VIEWS/Catalogues/Warehouses/Losses.vue'

    //Users
    import Users from 'VIEWS/Catalogues/Users/Users.vue'
    import UserRoles from 'VIEWS/Catalogues/Users/UserRoles.vue'
    import Employees from 'VIEWS/Catalogues/Users/Employees.vue'
    import Empresas from 'VIEWS/Catalogues/Users/Businesses.vue'

    export default {
        name: 'catalogues',
        components: {
            'titles': Titles,
            'tabs': Tabs,
            'title-tabs': TitleTabs,
            'discount': Discount,
            'products': Products,
            //'seasons':Seasons,
            'categories': Categories,
            'providers': Providers,
            'subcategories': Subcategory,
            'branches':Branches,
            'warehouses':Warehouses,
            'losses':Losses,
            'users':Users,      
            'employees': Employees,
            'movements': Movements,
            'users-roles': UserRoles,
            //'general-inventory': GeneralInventory,
            'transaction-types': TransactionTypes,
            'clients': Clients,
	        'empresas': Empresas
        },
        props: {
    
        },
        data() {
            return {
                tab_active: null,
                titletab_active: null,
                tabs_array:[
                    { img: icon_cash,  name: 'Ventas', selected: true, key: 'sales' },
                    { img: icon_inventory, name: 'Inventarios', selected: false, key: 'inventory'},
                    { img: icon_warehouse, name: 'Sucursales', selected: false, key: 'warehouses'},
                    { img: icon_reports, name: 'Usuarios', selected: false, key: 'users'}
                ],
                titletabs_array: [],
                title_sales: [
                    { name: 'Productos', selected: true},
                    //{ name: 'Temporadas', selected: false},
                    { name: 'Descuentos', selected: false},
                    { name: 'Formas de pago', selected: false},
                    { name: 'Clientes', selected: false}
                ],
                title_inventory: [
                    {name: 'Categorias', selected: true},
                    {name: 'Subcategorias', selected: false},
                    {name: 'Proveedores', selected: false},
                    //{name: 'Movimientos', selected: false},
                    //{name: 'Inventario general', selected: false}
                ],
                title_warehouses: [
                    {name: 'Sucursales', selected: true},
                    {name: 'Almacenes', selected: false},
                    {name: 'Motivos de merma', selected: false}
                ],
                title_users: [
                    {name: 'Usuarios', selected: true},
                    {name: 'Empleados', selected: false},
                    {name: 'Roles de usuario', selected: false},
	                {name: 'Empresas', selected: false}
                ]
            }
        },
        methods: {
            async controlTabs(event) {
                this.tab_active = event
                for (let i in this.tabs_array) {
                    if(this.tabs_array[i].selected){
                        this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
                    }
                }
                for (let i in this.titletabs_array) {
                    if(this.titletabs_array[i].selected){
                        this.titletab_active = this.titletabs_array[i].name
                    }
                }
            },
            async controlTitles(event) {
                this.titletab_active = event
                console.log(this.titletab_active)
            },
        },
        created(){
            for (let i in this.tabs_array) {
                if(this.tabs_array[i].selected){
                    this.tab_active = this.tabs_array[i].name
                    this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
                }
            }
            for (let i in this.titletabs_array) {
                if(this.titletabs_array[i].selected){
                    this.titletab_active = this.titletabs_array[i].name
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>