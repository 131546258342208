<template>
    <div class="container-fluid bg-white shadow-sm  mb-5 rounded border-box row p-2">

        <div class="col-12">
            <div class="row bg-lightgray p-3 my-3">
                <div class="col-4 p-0 opensans-bold">
                    <ki-input label="Buscar categoria:"
                        @input="searchCategory"
                        v-model="search_query"
                        placeholder="Ej: Chaleco..."
                        type="text"
                        full>
                    </ki-input>
                </div>
                <div class="col-3 d-flex justify-content-start align-items-end">
                    <button @click="open_modal = true" 
                        class="pills bg-white py-1">
                        <img :src="icon_add"/>
                        Agregar categoria
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12 py-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div class="col-12 opensans-bold text-dark" v-if="fetch_error">
            <p>
                Un error ha ocurrido intente más tarde
            </p>
        </div>

        <div class="col-12 mb-3"  v-if="!loading && !fetch_error">  
            <ki-table :data="data_table"
                
                :allow_headers="headers_allow"
                no_pagination color_headers="white" color_body="white">

                <template v-slot:Editar="data">
                    <button @click="selectEditCategory(data)"
                        class="pills bg-white p-0"
                        style="width: 50px;"
                        type="button">
                        <img :src="icon_write" />
                    </button>
                </template>

                <template v-slot:Borrar="data">
                    <button @click="selectDeleteCategory(data)"
                        class="pills bg-white p-0"
                        style="width: 50px;"
                        type="button">
                        <img :src="icon_delete_red" />
                    </button>
                </template>

            </ki-table> 
        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página: 
                    </label>
                    <ki-input @keyup.enter="navigate"
                        v-model="rows"
                        :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                        class="btn btn-primary text-white pills"
                        type="button"
                        :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                        v-model="current_page"
                        full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                        class="btn btn-primary text-white pills"
                        :disabled="current_page === total_pages"
                        type="button">
                        <b-icon icon="arrow-right"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <!--Modal  create edit category---->
        <ki-modal v-if="open_modal">
            <div class="row bg-white opensans-bold position-relative p-2" style="border-radius: 15px">
                <button @click="cancelCreateUpdateCategory"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); top: -10px; right: -10px"
                    type="button">
                    X
                </button>
                <div class="opensans-bold text-dark" v-if="!selected_id">
                    <p class="m-0">
                        Añadir nueva categoria
                    </p>
                </div>
                <div class="opensans-bold text-dark" v-if="selected_id">
                    <p class="m-0">
                        Editar categoria
                    </p>
                </div>
                <div class="col-12">
                    <ki-input label="Categoria" 
                        v-model="category_name"
                        type="text" 
                        placeholder="Ej:Camisa..." 
                        class="opensans-bold pt-1"
                        full>
                    </ki-input>
                    <span v-if="error_category_name" class="text-danger">
                        {{ error_category_name }}
                    </span>
                </div>
                <div class="col-12">
                    <ki-input label="Descripción" 
                        v-model="category_description"
                        type="text" 
                        placeholder="Ej: Escuela primaria" 
                        class="opensans-bold pt-1"
                        full>
                    </ki-input>
                    <span  class="text-danger">
                        {{ error_category_description }}
                    </span>
                </div>
                <div class="col-12 py-2 d-flex justify-content-end">
                    <button @click="cancelCreateUpdateCategory"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="createUpdateCategory"
                        class="btn btn-primary pills text-white ml-2"
                        tyoe="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>
        <!---Modal delete category-->
        <ki-modal v-if="show_delete_modal">
            <div class="row bg-white justify-content-center p-4 position-relative" style="border-radius: 15px;">
                <button @click="show_delete_modal = false"
                    class="position-absolute btn btn-white p-0"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2);"
                    type="button">
                    X
                </button>
                <div class="col-12 text-black text-center opensans-bold">
                    <p>
                        ¿Está seguro de borrar esta categoria
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button @click="deleteCategory"
                        class="btn btn-danger pills text-white"
                        type="button">
                        Borrar
                    </button>
                    <button @click="show_delete_modal = false"
                        class="btn btn-white pills ml-4"
                        type="button">
                        Cancelar
                    </button>
                </div>
            </div>
        </ki-modal>

        
    </div>
</template>

<script>
    //Icons
    import icon_write from 'ASSETS/icons/icon_write.png';
    import icon_delete_red from 'ASSETS/icons/icon_delete_red.png'; 
    import icon_add from 'ASSETS/icons/plus-blue-icon.png'

    //helpers
    import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'ki-categories',
    data() {
        return {
            icon_write,
            icon_delete_red,
            icon_add,
            headers_allow: [
                {key: 'category_name', label: 'Nombre'},
                {key: 'category_description', label: 'Descripción'},
                {key: 'Editar', label: 'Editar', width: 60},
                {key: 'Borrar', label: 'Borrar', width: 60}
            ],
            data_table: [],
            category_name: null,
            category_description: null,
            error_category_name: null,
            error_category_description: null,
            loading: true,
            fetch_error: false,
            open_modal: false,
            show_delete_modal: false,
            selected_id: null,
            notification: null,
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            categories: 'category/getCategories',
            category: 'category/getCategory',
            total_pages: 'category/getTotalPages',
            total_rows: 'category/getTotalRows'
        })
    },
    methods: {
        clearData(){
            this.open_modal = false
            this.show_delete_modal = false,
            this.category_description = null
            this.category_name = null
            this.selected_id = null

            for(let error in this.errors) {
                this.errors[error] = null
            }
        },
       async selectDeleteCategory(item) {
            this.selected_id = item.item.id_category
            this.show_delete_modal = true
       },
       async deleteCategory() {
            await this.$store.dispatch('category/deleteCategory', { id: this.selected_id})
            this.show_delete_modal = false
            this.selected_id = null
            await this.navigate()
       },
       async selectEditCategory(data) {
            this.selected_id = data.item.id_category
            await this.$store.dispatch('category/viewCategory', {id: this.selected_id})
            this.category_name = this.category.category_name
            this.category_description = this.category.category_description
            this.open_modal = true
       },
       cancelCreateUpdateCategory(){
            this.open_modal = false
            this.category_description = null
            this.category_name = null
            this.selected_id = null
       },
       async createUpdateCategory(){
            this.error_category_name = ''
            this.error_category_description = ''

            let is_complete = true

            if(_.isNull(this.category_name) || _.isEmpty(this.category_name)){
                this.error_category_name = '*Campo obligatorio'
                is_complete = false 
            }

            if(_.isNull(this.category_description) || _.isEmpty(this.category_description)){
                this.error_category_description = '*Campo obligatorio'
                is_complete = false
                return 
            }

            if(is_complete) {
                
                const payload = {
                    name: this.category_name,
                    description: this.category_description
                }

                if(this.selected_id) {
                    
                    let data = {
                        body: payload,
                        query: {
                            id_category: this.selected_id
                        }
                    }
                    await this.$store.dispatch('category/editCategory', data)
                    await this.navigate()
                    this.cancelCreateUpdateCategory()
                    return
                }

                 else {
                    await this.$store.dispatch('category/insertCategory', payload)
                    await this.navigate()
                    this.cancelCreateUpdateCategory()
                    return
                 }
            }
       },
       async searchCategory(){
            await this.navigate()
       },
       //paginator
        async navigate(){
            this.empty_search = false
            this.loading = true

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }

            await this.$store.dispatch('category/listCategories', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})

            if(!this.categories) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.categories
            }

            this.loading = false
                    
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async mounted() {
        try {
            //list of categories
            await this.navigate();
            this.fetch_error = false
        } 
        
        catch(e) {
            console.error(e)
            this.loading = false
            this.fetch_error = true
        }
    }
};
</script>

<style lang="scss" scoped>

</style>